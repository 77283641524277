var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-color-picker',{staticClass:"theme-picker",attrs:{"predefine":[
    '#409EFF',
    '#1890ff',
    '#41b584',
    '#11a983',
    '#325D41',
    '#2D4780',
    '#6959CD',
    '#304156',
    '#212121' ],"popper-class":"theme-picker-dropdown"},model:{value:(_vm.theme),callback:function ($$v) {_vm.theme=$$v},expression:"theme"}})}
var staticRenderFns = []

export { render, staticRenderFns }