<template>
  <el-menu router :default-active="$route.path">
    <menu-item
      v-for="item in menuTree"
      :key="item.url"
      :item="item"
    ></menu-item>
  </el-menu>
</template>

<script>
  import { travel as getMenuTree } from './use-menu-tree';

  import { MenuItem } from './MenuItem';

  export default {
    components: { MenuItem },
    data(){
      return {
       menuList:[
    {
        "menuId": 423,
        "name": "仪表盘",
        "url": "/dashboard",
        "path": null,
        "type": 1,
        "icon": "el-icon-s-platform",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 2,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "2",
        "children": [
            {
                "menuId": 424,
                "name": "实时监控",
                "url": "/dashboard/monitor",
                "path": null,
                "type": 2,
                "icon": "el-icon-monitor",
                "parentId": 423,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "2-1",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 2,
        "name": "商品",
        "url": "/commodity",
        "path": "commodity",
        "type": 1,
        "icon": "el-icon-s-goods",
        "parentId": null,
        "perms": "",
        "state": 1,
        "sort": 3,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "3",
        "children": [
            {
                "menuId": 13,
                "name": "所有商品",
                "url": "/commodity/allGoods",
                "path": "allGoods",
                "type": 2,
                "icon": "el-icon-shopping-bag-2",
                "parentId": 2,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-1",
                "children": [
                    {
                        "menuId": 21,
                        "name": "添加商品",
                        "url": "/product/addProduct",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:addProduct",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 22,
                        "name": "编辑商品",
                        "url": "/product/editProduct",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:editProduct",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 23,
                        "name": "商品列表",
                        "url": "/product/productList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:productList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 24,
                        "name": "批量修改上下架",
                        "url": "/product/batchProductStateUpdate",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:batchProductStateUpdate",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 25,
                        "name": "彻底删除商品",
                        "url": "/product/delProduct",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:delProduct",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 26,
                        "name": "商品详情",
                        "url": "/product/productDetail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:productDetail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 27,
                        "name": "恢复回收站商品",
                        "url": "/product/recoveryProduct",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:recoveryProduct",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 28,
                        "name": "刷新规格项目表",
                        "url": "/product/refreshSpecOption",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:refreshSpecOption",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 29,
                        "name": "商品删除(加到回收站)",
                        "url": "/product/softDelProduct",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:softDelProduct",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 370,
                        "name": "首页置顶状态设置",
                        "url": "/product/topIndexUpdate",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 13,
                        "perms": "product:topIndexUpdate",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-1-10",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 15,
                "name": "商品仓库",
                "url": "/commodity/warenspeicher",
                "path": "warenspeicher",
                "type": 2,
                "icon": "el-icon-box",
                "parentId": 2,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-2",
                "children": [
                    {
                        "menuId": 30,
                        "name": "仓库列表",
                        "url": "/warehouse/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 15,
                        "perms": "warehouse:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 31,
                        "name": "仓库详情",
                        "url": "/warehouse/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 15,
                        "perms": "warehouse:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 33,
                        "name": "删除仓库",
                        "url": "/warehouse/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 15,
                        "perms": "warehouse:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 34,
                        "name": "新增仓库",
                        "url": "/warehouse/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 15,
                        "perms": "warehouse:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 35,
                        "name": "编辑仓库",
                        "url": "/warehouse/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 15,
                        "perms": "warehouse:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-2-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 16,
                "name": "品牌管理",
                "url": "/commodity/brandManager",
                "path": "brandManager",
                "type": 2,
                "icon": "el-icon-postcard",
                "parentId": 2,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-3",
                "children": [
                    {
                        "menuId": 64,
                        "name": "添加品牌",
                        "url": "/brand/addBrand",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 16,
                        "perms": "brand:addBrand",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-3-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 65,
                        "name": "删除品牌",
                        "url": "/brand/delBrand",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 16,
                        "perms": "brand:delBrand",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-3-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 66,
                        "name": "编辑品牌",
                        "url": "/brand/editBrand",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 16,
                        "perms": "brand:editBrand",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-3-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 67,
                        "name": "品牌明细",
                        "url": "/brand/selectById",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 16,
                        "perms": "brand:selectById",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-3-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 68,
                        "name": "品牌列表",
                        "url": "/brand/selectList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 16,
                        "perms": "brand:selectList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-3-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 17,
                "name": "专题活动",
                "url": "/commodity/special",
                "path": "special",
                "type": 2,
                "icon": "el-icon-data-analysis",
                "parentId": 2,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-4",
                "children": [
                    {
                        "menuId": 79,
                        "name": "添加专题活动",
                        "url": "/special/addSpecial",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:addSpecial",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 80,
                        "name": "专题状态批量操作",
                        "url": "/special/batchUpdateState",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:batchUpdateState",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 81,
                        "name": "删除专题",
                        "url": "/special/delSpecial",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:delSpecial",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 82,
                        "name": "提前结束",
                        "url": "/special/earlyEnd",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:earlyEnd",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 83,
                        "name": "编辑排序号",
                        "url": "/special/editSort",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:editSort",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 84,
                        "name": "编辑专题活动",
                        "url": "/special/editSpecial",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:editSpecial",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 85,
                        "name": "重新开始",
                        "url": "/special/restart",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:restart",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 86,
                        "name": "专题活动明细",
                        "url": "/special/selectById",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:selectById",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 87,
                        "name": "专题活动列表",
                        "url": "/special/selectList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:selectList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 88,
                        "name": "专题活动包含的商品列表",
                        "url": "/special/getSpecialProductList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 17,
                        "perms": "special:getSpecialProductList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-4-10",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 18,
                "name": "商品评论",
                "url": "/commodity/remark",
                "path": "remark",
                "type": 2,
                "icon": "el-icon-chat-line-round",
                "parentId": 2,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-5",
                "children": [
                    {
                        "menuId": 89,
                        "name": "评论列表",
                        "url": "/productComment/list",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 18,
                        "perms": "productComment:list",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-5-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 90,
                        "name": "删除评论",
                        "url": "/productComment/delete",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 18,
                        "perms": "productComment:delete",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-5-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 91,
                        "name": "评论明细",
                        "url": "/productComment/detail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 18,
                        "perms": "productComment:detail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-5-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 92,
                        "name": "回复评论",
                        "url": "/productComment/reply",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 18,
                        "perms": "productComment:reply",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-5-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 19,
                "name": "商品分类",
                "url": "/commodity/classify",
                "path": "classify",
                "type": 2,
                "icon": "el-icon-connection",
                "parentId": 2,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-6",
                "children": [
                    {
                        "menuId": 93,
                        "name": "添加分类",
                        "url": "/productCategory/addCategory",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 19,
                        "perms": "productCategory:addCategory",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-6-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 94,
                        "name": "商品分类转移",
                        "url": "/productCategory/changeProductCategory",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 19,
                        "perms": "productCategory:changeProductCategory",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-6-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 95,
                        "name": "删除分类",
                        "url": "/productCategory/delCategory",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 19,
                        "perms": "productCategory:delCategory",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-6-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 96,
                        "name": "编辑分类",
                        "url": "/productCategory/editCategory",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 19,
                        "perms": "productCategory:editCategory",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-6-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 97,
                        "name": "编辑排序号",
                        "url": "/productCategory/editSort",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 19,
                        "perms": "productCategory:editSort",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-6-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 98,
                        "name": "分类列表",
                        "url": "/productCategory/selectList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 19,
                        "perms": "productCategory:selectList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-6-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 409,
                        "name": "分类详情",
                        "url": "/productCategory/detail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 19,
                        "perms": "productCategory:detail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-6-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 43,
                "name": "厂家管理",
                "url": "/commodity/factory",
                "path": "factory",
                "type": 2,
                "icon": "el-icon-office-building",
                "parentId": 2,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-7",
                "children": [
                    {
                        "menuId": 69,
                        "name": "删除厂家",
                        "url": "/manufacturers/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 43,
                        "perms": "manufacturers:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-7-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 70,
                        "name": "厂家明细",
                        "url": "/manufacturers/detail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 43,
                        "perms": "manufacturers:detail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-7-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 71,
                        "name": "厂家集合",
                        "url": "/manufacturers/detailList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 43,
                        "perms": "manufacturers:detailList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-7-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 76,
                        "name": "厂家管理列表",
                        "url": "/manufacturers/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 43,
                        "perms": "manufacturers:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-7-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 77,
                        "name": "添加厂家",
                        "url": "/manufacturers/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 43,
                        "perms": "manufacturers:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-7-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 78,
                        "name": "编辑厂家信息",
                        "url": "/manufacturers/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 43,
                        "perms": "manufacturers:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-7-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 371,
                "name": "商品属性",
                "url": "/commodity/attribute",
                "path": "attribute",
                "type": 2,
                "icon": "el-icon-finished",
                "parentId": 2,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-8",
                "children": [
                    {
                        "menuId": 385,
                        "name": "产品属性列表",
                        "url": "/productAgentTag/getProductAgentTagList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 371,
                        "perms": "productAgentTag:getProductAgentTagList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-8-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 386,
                        "name": "添加属性",
                        "url": "/productAgentTag/addProductAgentTag",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 371,
                        "perms": "productAgentTag:addProductAgentTag",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-8-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 387,
                        "name": "编辑属性",
                        "url": "/productAgentTag/editProductAgentTag",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 371,
                        "perms": "productAgentTag:editProductAgentTag",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-8-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 388,
                        "name": "删除属性",
                        "url": "/productAgentTag/delProductAgentTag",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 371,
                        "perms": "productAgentTag:delProductAgentTag",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-8-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 413,
                "name": "游客商品",
                "url": "/commodity/tourist",
                "path": "tourist",
                "type": 2,
                "icon": "el-icon-shopping-bag-2",
                "parentId": 2,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "3-9",
                "children": [
                    {
                        "menuId": 414,
                        "name": "列表",
                        "url": "/visit/product/list",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 413,
                        "perms": "visit:product:list",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-9-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 415,
                        "name": "新增",
                        "url": "/visit/product/insert",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 413,
                        "perms": "visit:product:insert",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-9-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 416,
                        "name": "编辑",
                        "url": "/visit/product/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 413,
                        "perms": "visit:product:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-9-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 417,
                        "name": "删除",
                        "url": "/visit/product/delete",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 413,
                        "perms": "visit:product:delete",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "3-9-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 4,
        "name": "会员",
        "url": "/member",
        "path": "member",
        "type": 1,
        "icon": "el-icon-s-custom",
        "parentId": null,
        "perms": "",
        "state": 1,
        "sort": 3,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "4",
        "children": [
            {
                "menuId": 100,
                "name": "会员管理",
                "url": "/member/memberManagement",
                "path": "memberManagement",
                "type": 2,
                "icon": "el-icon-user",
                "parentId": 4,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "4-1",
                "children": [
                    {
                        "menuId": 104,
                        "name": "批量操作",
                        "url": "/member/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "member:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 105,
                        "name": "会员详情",
                        "url": "/member/detail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "member:detail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 106,
                        "name": "会员管理列表",
                        "url": "/member/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "member:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 107,
                        "name": "会员搜索",
                        "url": "/member/getMemberList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "member:getMemberList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 108,
                        "name": "充值余额",
                        "url": "/member/upBalance",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "member:upBalance",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 109,
                        "name": "充值积分",
                        "url": "/member/upMinute",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "member:upMinute",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 288,
                        "name": "导出推广信息的下级记录或子账号列表",
                        "url": "/agentExtend/exportMebExtendList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "agentExtend:exportMebExtendList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 289,
                        "name": "推广信息的下级记录或子账号列表",
                        "url": "/agentExtend/getMebExtendList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "agentExtend:getMebExtendList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 290,
                        "name": "会员的推广信息",
                        "url": "/agentExtend/getMebExtendView",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "agentExtend:getMebExtendView",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 291,
                        "name": "编辑会员所有信息",
                        "url": "/agentExtend/updateAgentExtend",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 100,
                        "perms": "agentExtend:updateAgentExtend",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-1-10",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 101,
                "name": "会员等级",
                "url": "/member/memberOrder",
                "path": "memberOrder",
                "type": 2,
                "icon": "el-icon-wallet",
                "parentId": 4,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "4-2",
                "children": [
                    {
                        "menuId": 111,
                        "name": "会员等级删除接口",
                        "url": "/vipLevel/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 101,
                        "perms": "vipLevel:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 112,
                        "name": "会员等级列表查询接口",
                        "url": "/vipLevel/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 101,
                        "perms": "vipLevel:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 113,
                        "name": "会员等级详情接口",
                        "url": "/vipLevel/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 101,
                        "perms": "vipLevel:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 115,
                        "name": "会员等级新增接口",
                        "url": "/vipLevel/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 101,
                        "perms": "vipLevel:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 116,
                        "name": "会员等级修改接口",
                        "url": "/vipLevel/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 101,
                        "perms": "vipLevel:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-2-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 117,
                        "name": "会员等级状态批量修改接口",
                        "url": "/vipLevel/updateList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 101,
                        "perms": "vipLevel:updateList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-2-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 102,
                "name": "会员卡",
                "url": "/member/memberCard",
                "path": "memberCard",
                "type": 2,
                "icon": "el-icon-bank-card",
                "parentId": 4,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "4-3",
                "children": [
                    {
                        "menuId": 118,
                        "name": "操作会员卡",
                        "url": "/vipCard/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 102,
                        "perms": "vipCard:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-3-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 119,
                        "name": "会员卡列表查询接口",
                        "url": "/vipCard/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 102,
                        "perms": "vipCard:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-3-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 120,
                        "name": "会员卡详情",
                        "url": "/vipCard/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 102,
                        "perms": "vipCard:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-3-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 121,
                        "name": "添加会员卡",
                        "url": "/vipCard/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 102,
                        "perms": "vipCard:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-3-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 122,
                        "name": "编辑会员卡",
                        "url": "/vipCard/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 102,
                        "perms": "vipCard:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "4-3-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 3,
        "name": "订单",
        "url": "/order",
        "path": "order",
        "type": 1,
        "icon": "el-icon-s-order",
        "parentId": null,
        "perms": "",
        "state": 1,
        "sort": 4,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "5",
        "children": [
            {
                "menuId": 11,
                "name": "订单列表",
                "url": "/order/orderList",
                "path": "orderList",
                "type": 2,
                "icon": "el-icon-sold-out",
                "parentId": 3,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "5-1",
                "children": [
                    {
                        "menuId": 123,
                        "name": "订单列表",
                        "url": "/order/orderList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:orderList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 124,
                        "name": "订单备注明细(售后通用)",
                        "url": "/order/markOrderDetail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:markOrderDetail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 125,
                        "name": "订单编辑备注(售后通用)",
                        "url": "/order/markOrder",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:markOrder",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 126,
                        "name": "删除订单(售后通用)",
                        "url": "/order/delete",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:delete",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 127,
                        "name": "订单发货明细",
                        "url": "/order/sendDetail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:sendDetail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 128,
                        "name": "订单发货",
                        "url": "/order/send",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:send",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 129,
                        "name": "订单详情",
                        "url": "/order/orderDetail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:orderDetail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 130,
                        "name": "修改订单收货地址",
                        "url": "/order/updateAddress",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:updateAddress",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 131,
                        "name": "查看物流",
                        "url": "/order/selectLogistics",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:selectLogistics",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 132,
                        "name": "修改物流",
                        "url": "/order/updateLogistics",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:updateLogistics",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-10",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 133,
                        "name": "充值余额界面获取订单",
                        "url": "/order/findAllOrderByUserId",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:findAllOrderByUserId",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-11",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 310,
                        "name": "导出订单",
                        "url": "/order/export",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 11,
                        "perms": "order:export",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-1-12",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 12,
                "name": "售后订单",
                "url": "/order/saleOrder",
                "path": "saleOrder",
                "type": 2,
                "icon": "el-icon-sell",
                "parentId": 3,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "5-2",
                "children": [
                    {
                        "menuId": 134,
                        "name": "订单列表",
                        "url": "/orderRefund/orderList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 12,
                        "perms": "orderRefund:orderList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 135,
                        "name": "订单详情",
                        "url": "/orderRefund/orderDetail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 12,
                        "perms": "orderRefund:orderDetail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 136,
                        "name": "拒绝退款申请",
                        "url": "/orderRefund/refundRefuse",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 12,
                        "perms": "orderRefund:refundRefuse",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 137,
                        "name": "退款地址列表",
                        "url": "/orderRefund/refundAddressList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 12,
                        "perms": "orderRefund:refundAddressList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 138,
                        "name": "退款申请通过",
                        "url": "/orderRefund/refundPass",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 12,
                        "perms": "orderRefund:refundPass",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-2-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 139,
                        "name": "查看退货物流",
                        "url": "/orderRefund/selectRefundLogistics",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 12,
                        "perms": "orderRefund:selectRefundLogistics",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-2-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 140,
                        "name": "确定收货并退款",
                        "url": "/orderRefund/refundConfirm",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 12,
                        "perms": "orderRefund:refundConfirm",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "5-2-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 36,
        "name": "财务",
        "url": "/finance",
        "path": "finance",
        "type": 1,
        "icon": "el-icon-s-finance",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 5,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "6",
        "children": [
            {
                "menuId": 44,
                "name": "充值记录",
                "url": "/finance/payRecord",
                "path": "payRecord",
                "type": 2,
                "icon": "el-icon-collection",
                "parentId": 36,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "6-1",
                "children": [
                    {
                        "menuId": 141,
                        "name": "充值记录列表",
                        "url": "/recharge/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 44,
                        "perms": "recharge:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 142,
                        "name": "导出充值记录",
                        "url": "/recharge/export",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 44,
                        "perms": "recharge:export",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 45,
                "name": "提现申请",
                "url": "/finance/withdraw",
                "path": "withdraw",
                "type": 2,
                "icon": "el-icon-printer",
                "parentId": 36,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "6-2",
                "children": [
                    {
                        "menuId": 143,
                        "name": "提现申请列表",
                        "url": "/withdraw/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 45,
                        "perms": "withdraw:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 144,
                        "name": "导出提现申请",
                        "url": "/withdraw/export",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 45,
                        "perms": "withdraw:export",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 145,
                        "name": "拒绝提现",
                        "url": "/withdraw/refuse",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 45,
                        "perms": "withdraw:refuse",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 146,
                        "name": "提现",
                        "url": "/withdraw/withdrawUser",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 45,
                        "perms": "withdraw:withdrawUser",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 46,
                "name": "余额明细",
                "url": "/finance/balance",
                "path": "balance",
                "type": 2,
                "icon": "el-icon-toilet-paper",
                "parentId": 36,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "6-3",
                "children": [
                    {
                        "menuId": 147,
                        "name": "余额明细列表",
                        "url": "/balance/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 46,
                        "perms": "balance:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-3-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 148,
                        "name": "导出余额明细",
                        "url": "/balance/export",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 46,
                        "perms": "balance:export",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-3-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 47,
                "name": "积分明细",
                "url": "/finance/integration",
                "path": "integration",
                "type": 2,
                "icon": "el-icon-football",
                "parentId": 36,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "6-4",
                "children": [
                    {
                        "menuId": 149,
                        "name": "积分明细列表",
                        "url": "/balance/score/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 47,
                        "perms": "balance:score:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-4-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 48,
                "name": "账单下载",
                "url": "/finance/downloadBill",
                "path": "downloadBill",
                "type": 2,
                "icon": "el-icon-download",
                "parentId": 36,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "6-5",
                "children": [
                    {
                        "menuId": 150,
                        "name": "导出账单",
                        "url": "/order/bill/export",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 48,
                        "perms": "order:bill:export",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "6-5-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 49,
                "name": "资金中心",
                "url": "/finance/capitalCenter",
                "path": "capitalCenter",
                "type": 2,
                "icon": "el-icon-download",
                "parentId": 36,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "6-6",
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
                {
                "menuId": 50,
                "name": "收支明细",
                "url": "/finance/payDetail",
                "path": "payDetail",
                "type": 2,
                "icon": "el-icon-download",
                "parentId": 36,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "6-7",
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 38,
        "name": "数据统计",
        "url": "/statistics",
        "path": "statistics",
        "type": 1,
        "icon": "el-icon-s-data",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 6,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "7",
        "children": [
            {
                "menuId": 49,
                "name": "厂家品牌",
                "url": "/statistics/honyone",
                "path": "honyone",
                "type": 2,
                "icon": "el-icon-bank-card",
                "parentId": 38,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "7-1",
                "children": [
                    {
                        "menuId": 72,
                        "name": "导出品牌统计",
                        "url": "/manufacturers/exportBrandSumList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 49,
                        "perms": "manufacturers:exportBrandSumList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "7-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 73,
                        "name": "导出厂家统计",
                        "url": "/manufacturers/exportFactorySumList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 49,
                        "perms": "manufacturers:exportFactorySumList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "7-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 74,
                        "name": "品牌统计",
                        "url": "/manufacturers/getBrandSumList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 49,
                        "perms": "manufacturers:getBrandSumList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "7-1-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 75,
                        "name": "厂家统计",
                        "url": "/manufacturers/getFactorySumList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 49,
                        "perms": "manufacturers:getFactorySumList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "7-1-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 367,
                "name": "数据概况",
                "url": "/statistics/dataOverview",
                "path": "dataOverview",
                "type": 2,
                "icon": "el-icon-bangzhu",
                "parentId": 38,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": true,
                "redirect": "",
                "index": "7-2",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 1,
        "name": "内容配置",
        "url": "/shop",
        "path": "shop",
        "type": 1,
        "icon": "el-icon-s-shop",
        "parentId": null,
        "perms": "",
        "state": 1,
        "sort": 7,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "8",
        "children": [
            {
                "menuId": 50,
                "name": "文章管理",
                "url": "/shop/articleManager",
                "path": "articleManager",
                "type": 2,
                "icon": "el-icon-tickets",
                "parentId": 1,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "8-1",
                "children": [
                    {
                        "menuId": 151,
                        "name": "文章列表",
                        "url": "/article/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "article:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 152,
                        "name": "删除文章",
                        "url": "/article/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "article:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 153,
                        "name": "文章推送",
                        "url": "/article/push",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "article:push",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 154,
                        "name": "文章明细",
                        "url": "/article/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "article:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 155,
                        "name": "添加文章",
                        "url": "/article/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "article:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 156,
                        "name": "编辑文章",
                        "url": "/article/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "article:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 157,
                        "name": "删除文章和公告分类",
                        "url": "/articleNoticeCategory/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "articleNoticeCategory:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 158,
                        "name": "文章和公告分类列表",
                        "url": "/articleNoticeCategory/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "articleNoticeCategory:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 159,
                        "name": "文章和公告分类明细",
                        "url": "/articleNoticeCategory/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "articleNoticeCategory:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 160,
                        "name": "文章和公告分类下拉框",
                        "url": "/articleNoticeCategory/selectType",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "articleNoticeCategory:selectType",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-10",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 161,
                        "name": "添加文章和公告分类",
                        "url": "/articleNoticeCategory/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "articleNoticeCategory:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-11",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 162,
                        "name": "编辑文章和公告分类",
                        "url": "/articleNoticeCategory/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 50,
                        "perms": "articleNoticeCategory:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-1-12",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 51,
                "name": "公告管理",
                "url": "/shop/bulletinManagement",
                "path": "bulletinManagement",
                "type": 2,
                "icon": "el-icon-bell",
                "parentId": 1,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "8-2",
                "children": [
                    {
                        "menuId": 163,
                        "name": "公告列表",
                        "url": "/notice/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 51,
                        "perms": "notice:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 164,
                        "name": "删除公告",
                        "url": "/notice/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 51,
                        "perms": "notice:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 165,
                        "name": "公告明细",
                        "url": "/notice/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 51,
                        "perms": "notice:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 166,
                        "name": "添加公告",
                        "url": "/notice/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 51,
                        "perms": "notice:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 167,
                        "name": "编辑公告",
                        "url": "/notice/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 51,
                        "perms": "notice:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-2-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 52,
                "name": "首页推送",
                "url": "/shop/homePagePush",
                "path": "homePagePush",
                "type": 2,
                "icon": "el-icon-ship",
                "parentId": 1,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "8-3",
                "children": [
                    {
                        "menuId": 168,
                        "name": "删除首页推送",
                        "url": "/indexPush/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 52,
                        "perms": "indexPush:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-3-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 169,
                        "name": "首页推送列表",
                        "url": "/indexPush/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 52,
                        "perms": "indexPush:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-3-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 171,
                        "name": "添加首页推送",
                        "url": "/indexPush/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 52,
                        "perms": "indexPush:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-3-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 172,
                        "name": "编辑首页推送",
                        "url": "/indexPush/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 52,
                        "perms": "indexPush:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-3-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 344,
                        "name": "修改状态",
                        "url": "/indexPush/updateState",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 52,
                        "perms": "indexPush:updateState",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-3-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 53,
                "name": "退货地址",
                "url": "/shop/returnAddress",
                "path": "returnAddress",
                "type": 2,
                "icon": "el-icon-map-location",
                "parentId": 1,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "8-4",
                "children": [
                    {
                        "menuId": 173,
                        "name": "退货地址列表",
                        "url": "/returnAddress/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 53,
                        "perms": "returnAddress:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-4-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 174,
                        "name": "删除退货地址",
                        "url": "/returnAddress/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 53,
                        "perms": "returnAddress:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-4-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 177,
                        "name": "退货地址明细",
                        "url": "/returnAddress/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 53,
                        "perms": "returnAddress:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-4-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 178,
                        "name": "添加退货地址",
                        "url": "/returnAddress/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 53,
                        "perms": "returnAddress:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-4-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 179,
                        "name": "编辑退货地址",
                        "url": "/returnAddress/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 53,
                        "perms": "returnAddress:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-4-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 54,
                "name": "素材管理",
                "url": "/shop/materialManagement",
                "path": "materialManagement",
                "type": 2,
                "icon": "el-icon-folder-opened",
                "parentId": 1,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "8-5",
                "children": [
                    {
                        "menuId": 180,
                        "name": "分组列表",
                        "url": "/file/dirList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:dirList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 181,
                        "name": "编辑分组",
                        "url": "/file/editDir",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:editDir",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 182,
                        "name": "删除分组",
                        "url": "/file/delDir",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:delDir",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 183,
                        "name": "添加分组",
                        "url": "/file/addDir",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:addDir",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 184,
                        "name": "上传文件",
                        "url": "/file/upload",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:upload",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 185,
                        "name": "文件列表",
                        "url": "/file/fileList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:fileList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 186,
                        "name": "删除文件",
                        "url": "/file/delFile",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:delFile",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 187,
                        "name": "获取数据存储设置信息",
                        "url": "/file/getConfig",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:getConfig",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 188,
                        "name": "保存数据存储设置信息",
                        "url": "/file/saveConfig",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 54,
                        "perms": "file:saveConfig",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-5-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 55,
                "name": "配送设置",
                "url": "/shop/delivery",
                "path": "delivery",
                "type": 2,
                "icon": "el-icon-truck",
                "parentId": 1,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "8-6",
                "children": [
                    {
                        "menuId": 189,
                        "name": "物流配置详情接口",
                        "url": "/freight/logisticsConfig/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:logisticsConfig:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 190,
                        "name": "保存物流配置",
                        "url": "/freight/logisticsConfig/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:logisticsConfig:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 191,
                        "name": "快递公司列表查询接口",
                        "url": "/express/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "express:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 192,
                        "name": "快递公司详情接口",
                        "url": "/express/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "express:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 193,
                        "name": "快递公司新增接口",
                        "url": "/express/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "express:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 194,
                        "name": "快递公司修改接口",
                        "url": "/express/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "express:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 195,
                        "name": "快递公司删除接口",
                        "url": "/express/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "express:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 196,
                        "name": "运费设置列表查询接口",
                        "url": "/freight/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 197,
                        "name": "运费设置详情接口",
                        "url": "/freight/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 198,
                        "name": "添加运费设置",
                        "url": "/freight/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-10",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 199,
                        "name": "编辑运费设置",
                        "url": "/freight/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-11",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 200,
                        "name": "删除运费设置",
                        "url": "/freight/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-12",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 201,
                        "name": "编辑默认状态",
                        "url": "/freight/alter",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 55,
                        "perms": "freight:alter",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-6-13",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 313,
                "name": "导航图标(金刚区)",
                "url": "/shop/nxdyigate",
                "path": "nxdyigate",
                "type": 2,
                "icon": "el-icon-location-outline",
                "parentId": 1,
                "perms": "",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "8-7",
                "children": [
                    {
                        "menuId": 315,
                        "name": "列表",
                        "url": "/homeMenu/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 313,
                        "perms": "homeMenu:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-7-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 345,
                        "name": "删除",
                        "url": "/homeMenu/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 313,
                        "perms": "homeMenu:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-7-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 346,
                        "name": "明细",
                        "url": "/homeMenu/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 313,
                        "perms": "homeMenu:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-7-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 347,
                        "name": "新增",
                        "url": "/homeMenu/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 313,
                        "perms": "homeMenu:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-7-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 348,
                        "name": "编辑",
                        "url": "/homeMenu/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 313,
                        "perms": "homeMenu:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-7-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 349,
                        "name": "批量修改状态",
                        "url": "/homeMenu/updateList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 313,
                        "perms": "homeMenu:updateList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-7-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 314,
                "name": "幻灯片",
                "url": "/shop/powerPoint",
                "path": "powerPoint",
                "type": 2,
                "icon": "el-icon-video-camera",
                "parentId": 1,
                "perms": null,
                "state": 0,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": true,
                "redirect": "",
                "index": "8-8",
                "children": [
                    {
                        "menuId": 350,
                        "name": "列表",
                        "url": "/banner/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 314,
                        "perms": "banner:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-8-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 351,
                        "name": "明细",
                        "url": "/banner/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 314,
                        "perms": "banner:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-8-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 352,
                        "name": "删除",
                        "url": "/banner/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 314,
                        "perms": "banner:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-8-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 353,
                        "name": "新增",
                        "url": "/banner/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 314,
                        "perms": "banner:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-8-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 354,
                        "name": "编辑",
                        "url": "/banner/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 314,
                        "perms": "banner:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-8-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 355,
                        "name": "批量修改状态",
                        "url": "/banner/updateList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 314,
                        "perms": "banner:updateList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-8-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 318,
                "name": "广告设置",
                "url": "/shop/advertising",
                "path": "advertising",
                "type": 2,
                "icon": "el-icon-s-operation",
                "parentId": 1,
                "perms": null,
                "state": 0,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": true,
                "redirect": "",
                "index": "8-9",
                "children": [
                    {
                        "menuId": 356,
                        "name": "列表",
                        "url": "/advert/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 318,
                        "perms": "advert:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-9-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 357,
                        "name": "明细",
                        "url": "/advert/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 318,
                        "perms": "advert:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-9-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 358,
                        "name": "删除",
                        "url": "/advert/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 318,
                        "perms": "advert:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-9-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 359,
                        "name": "新增",
                        "url": "/advert/sev",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 318,
                        "perms": "advert:sev",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-9-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 360,
                        "name": "编辑",
                        "url": "/advert/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 318,
                        "perms": "advert:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-9-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 361,
                        "name": "批量修改状态",
                        "url": "/advert/updateList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 318,
                        "perms": "advert:updateList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-9-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 428,
                "name": "广告配置",
                "url": "/advertising",
                "path": null,
                "type": 1,
                "icon": "el-icon-video-camera",
                "parentId": 1,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "8-10",
                "children": [
                    {
                        "menuId": 429,
                        "name": "启动广告",
                        "url": "/shop/advertisement",
                        "path": "advertisement",
                        "type": 2,
                        "icon": "el-icon-star-off",
                        "parentId": 428,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-10-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 430,
                        "name": "首页轮播图",
                        "url": "/shop/powerPoint",
                        "path": "powerPoint",
                        "type": 2,
                        "icon": "el-icon-picture-outline",
                        "parentId": 428,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-10-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 431,
                        "name": "其他内容",
                        "url": "/shop/advertising",
                        "path": "advertising",
                        "type": 2,
                        "icon": "el-icon-s-opportunity",
                        "parentId": 428,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "8-10-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 39,
        "name": "营销",
        "url": "/marketing",
        "path": "marketing",
        "type": 1,
        "icon": "el-icon-s-marketing",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 8,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "9",
        "children": [
            {
                "menuId": 56,
                "name": "优惠券",
                "url": "/marketing/coupon",
                "path": "coupon",
                "type": 2,
                "icon": "el-icon-potato-strips",
                "parentId": 39,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "9-1",
                "children": [
                    {
                        "menuId": 202,
                        "name": "优惠券列表",
                        "url": "/coupon/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 56,
                        "perms": "coupon:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 203,
                        "name": "新增优惠券",
                        "url": "/coupon/insertCoupon",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 56,
                        "perms": "coupon:insertCoupon",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 204,
                        "name": "修改优惠券",
                        "url": "/coupon/updateCoupon",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 56,
                        "perms": "coupon:updateCoupon",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-1-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 205,
                        "name": "手动派发优惠券",
                        "url": "/coupon/handCoupon",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 56,
                        "perms": "coupon:handCoupon",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-1-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 206,
                        "name": "批量删除优惠券",
                        "url": "/coupon/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 56,
                        "perms": "coupon:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-1-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 207,
                        "name": "批量修改优惠券状态",
                        "url": "/coupon/updateList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 56,
                        "perms": "coupon:updateList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-1-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 433,
                        "name": "手动批量派发优惠券",
                        "url": "/coupon/batchHandCoupon",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 56,
                        "perms": "coupon:batchHandCoupon",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-1-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 57,
                "name": "整点秒杀",
                "url": "/marketing/seckilling",
                "path": "seckilling",
                "type": 2,
                "icon": "el-icon-time",
                "parentId": 39,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "9-2",
                "children": [
                    {
                        "menuId": 208,
                        "name": "新建秒杀活动",
                        "url": "/seckill/addSeckill",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 57,
                        "perms": "seckill:addSeckill",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 209,
                        "name": "秒杀活动列表",
                        "url": "/seckill/list",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 57,
                        "perms": "seckill:list",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 210,
                        "name": "上下架",
                        "url": "/seckill/updateState",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 57,
                        "perms": "seckill:updateState",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 211,
                        "name": "秒杀活动明细",
                        "url": "/seckill/detail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 57,
                        "perms": "seckill:detail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 212,
                        "name": "秒杀活动选择商品列表",
                        "url": "/seckill/selectProduct",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 57,
                        "perms": "seckill:selectProduct",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-2-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 58,
                "name": "代理中心",
                "url": "/marketing/agency",
                "path": "agency",
                "type": 2,
                "icon": "el-icon-camera",
                "parentId": 39,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "9-3",
                "children": [
                    {
                        "menuId": 213,
                        "name": "代理等级删除接口",
                        "url": "/agentLevel/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agentLevel:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 214,
                        "name": "会员等级列表查询接口",
                        "url": "/agentLevel/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agentLevel:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 215,
                        "name": "代理等级新增接口",
                        "url": "/agentLevel/insert",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agentLevel:insert",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 216,
                        "name": "代理等级详情接口",
                        "url": "/agentLevel/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agentLevel:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 217,
                        "name": "代理等级下拉框",
                        "url": "/agentLevel/selectList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agentLevel:selectList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 218,
                        "name": "代理等级修改接口",
                        "url": "/agentLevel/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agentLevel:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 219,
                        "name": "代理等级状态批量修改接口",
                        "url": "/agentLevel/updateList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agentLevel:updateList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 220,
                        "name": "代理中心代理商管理列表",
                        "url": "/agent/getAgentList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:getAgentList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 221,
                        "name": "代理中心提现记录列表",
                        "url": "/agent/getWithdrawalList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:getWithdrawalList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 222,
                        "name": "代理中心提现申请的各状态处理",
                        "url": "/agent/doWithdrawal",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:doWithdrawal",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-10",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 223,
                        "name": "代理中心提现打钱",
                        "url": "/agent/postWithdrawal",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:postWithdrawal",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-11",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 418,
                        "name": "代理中心提现申请(取消确认处理)",
                        "url": "/agent/cancel/confirm/withdrawal",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:cancel:confirm:withdrawal",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-12",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 419,
                        "name": "代理中心提现申请(确认处理)",
                        "url": "/agent/confirm/withdrawal/agent",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:confirm:withdrawal:agent",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-13",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 420,
                        "name": "代理中心提现申请(拒绝处理)",
                        "url": "/agent/refuse/withdrawal/agent",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:refuse:withdrawal:agent",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-14",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 421,
                        "name": "导出提现申请的佣金详情-新",
                        "url": "/agent/export/Order/Profit/List",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 58,
                        "perms": "agent:export:Order:Profit:List",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-3-15",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 316,
                "name": "积分商场",
                "url": "/marketing/integralMall",
                "path": "integralMall",
                "type": 2,
                "icon": "el-icon-present",
                "parentId": 39,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "9-4",
                "children": [
                    {
                        "menuId": 319,
                        "name": "基础设置",
                        "url": "/marketing/integralMall/integralSet",
                        "path": "integralSet",
                        "type": 2,
                        "icon": "el-icon-setting",
                        "parentId": 316,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-4-1",
                        "children": [
                            {
                                "menuId": 323,
                                "name": "查询基础设置信息",
                                "url": "/integral/mall/settings/detail",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 319,
                                "perms": "integral:mall:settings:detail",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-1-1",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 324,
                                "name": "保存基础设置信息",
                                "url": "/integral/mall/settings/save",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 319,
                                "perms": "integral:mall:settings:save",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-1-2",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 325,
                                "name": "轮播图列表",
                                "url": "/integral/mall/banner/list",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 319,
                                "perms": "integral:mall:banner:list",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-1-3",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 326,
                                "name": "添加轮播图",
                                "url": "/integral/mall/banner/insert",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 319,
                                "perms": "integral:mall:banner:insert",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-1-4",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 327,
                                "name": "编辑轮播图",
                                "url": "/integral/mall/banner/update",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 319,
                                "perms": "integral:mall:banner:update",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-1-5",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 328,
                                "name": "删除轮播图",
                                "url": "/integral/mall/banner/delete",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 319,
                                "perms": "integral:mall:banner:delete",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-1-6",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            }
                        ],
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 320,
                        "name": "分类管理",
                        "url": "/marketing/integralMall/sortManagement",
                        "path": "sortManagement",
                        "type": 2,
                        "icon": "el-icon-s-operation",
                        "parentId": 316,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-4-2",
                        "children": [
                            {
                                "menuId": 329,
                                "name": "分类列表",
                                "url": "/integral/mall/category/list",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 320,
                                "perms": "integral:mall:category:list",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-2-1",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 330,
                                "name": "添加分类",
                                "url": "/integral/mall/category/insert",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 320,
                                "perms": "integral:mall:category:insert",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-2-2",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 331,
                                "name": "编辑分类",
                                "url": "/integral/mall/category/update",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 320,
                                "perms": "integral:mall:category:update",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-2-3",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 332,
                                "name": "删除分类",
                                "url": "/integral/mall/category/delete",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 320,
                                "perms": "integral:mall:category:delete",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-2-4",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            }
                        ],
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 321,
                        "name": "商品管理",
                        "url": "/marketing/integralMall/merchandiseControl",
                        "path": "merchandiseControl",
                        "type": 2,
                        "icon": "el-icon-goods",
                        "parentId": 316,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-4-3",
                        "children": [
                            {
                                "menuId": 333,
                                "name": "添加积分商品",
                                "url": "/integral/mall/product/insert",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:insert",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-1",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 334,
                                "name": "编辑积分商品",
                                "url": "/integral/mall/product/update",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:update",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-2",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 335,
                                "name": "积分商品删除至回收站",
                                "url": "/integral/mall/product/batch/softDelete",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:batch:softDelete",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-3",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 336,
                                "name": "彻底删除积分商品",
                                "url": "/integral/mall/product/batch/delete",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:batch:delete",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-4",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 337,
                                "name": "恢复回收站积分商品",
                                "url": "/integral/mall/product/batch/recovery",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:batch:recovery",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-5",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 338,
                                "name": "批量上下架",
                                "url": "/integral/mall/product/batch/updateState",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:batch:updateState",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-6",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 339,
                                "name": "设置是否推荐",
                                "url": "/integral/mall/product/recommend/update",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:recommend:update",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-7",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 340,
                                "name": "商品列表",
                                "url": "/integral/mall/product/list",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:list",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-8",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 341,
                                "name": "商品明细",
                                "url": "/integral/mall/product/detail",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 321,
                                "perms": "integral:mall:product:detail",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-3-9",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            }
                        ],
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 322,
                        "name": "兑换记录",
                        "url": "/marketing/integralMall/exchangeRecord",
                        "path": "exchangeRecord",
                        "type": 2,
                        "icon": "el-icon-document",
                        "parentId": 316,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-4-4",
                        "children": [
                            {
                                "menuId": 343,
                                "name": "兑换列表",
                                "url": "/integral/mall/exchange/record/list",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 322,
                                "perms": "integral:mall:exchange:record:list",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-4-4-1",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            }
                        ],
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 372,
                "name": "小程序直播",
                "url": "/marketing/appletLive",
                "path": "appletLive",
                "type": 2,
                "icon": "el-icon-video-play",
                "parentId": 39,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "9-5",
                "children": [
                    {
                        "menuId": 373,
                        "name": "直播列表",
                        "url": "/marketing/appletLive/sinatv",
                        "path": "sinatv",
                        "type": 2,
                        "icon": "el-icon-video-camera",
                        "parentId": 372,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-5-1",
                        "children": [
                            {
                                "menuId": 376,
                                "name": "直播列表",
                                "url": "/play/getListLiveRoom",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 373,
                                "perms": "play:getListLiveRoom",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-1-1",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 377,
                                "name": "创建直播",
                                "url": "/play/addPlayRoom",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 373,
                                "perms": "play:addPlayRoom",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-1-2",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 378,
                                "name": "直播间导入商品",
                                "url": "/play/addRoomGoods",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 373,
                                "perms": "play:addRoomGoods",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-1-3",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 379,
                                "name": "编辑直播间",
                                "url": "/play/editPlayRoom",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 373,
                                "perms": "play:editPlayRoom",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-1-4",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 380,
                                "name": "删除直播间",
                                "url": "/play/delPlayRoom",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 373,
                                "perms": "play:delPlayRoom",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-1-5",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            }
                        ],
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 374,
                        "name": "直播分类",
                        "url": "/marketing/playCategory",
                        "path": "playCategory",
                        "type": 2,
                        "icon": "el-icon-s-operation",
                        "parentId": 372,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": true,
                        "redirect": "",
                        "index": "9-5-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 375,
                        "name": "直播商品",
                        "url": "/marketing/appletLive/liveGoods",
                        "path": "liveGoods",
                        "type": 2,
                        "icon": "el-icon-goods",
                        "parentId": 372,
                        "perms": null,
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": true,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-5-3",
                        "children": [
                            {
                                "menuId": 381,
                                "name": "直播商品添加",
                                "url": "/playGoods/addGoods",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 375,
                                "perms": "playGoods:addGoods",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-3-1",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 382,
                                "name": "直播商品编辑",
                                "url": "/playGoods/editGoods",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 375,
                                "perms": "playGoods:editGoods",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-3-2",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 383,
                                "name": "微信商品列表",
                                "url": "/playGoods/getWxGoodsList",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 375,
                                "perms": "playGoods:getWxGoodsList",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-3-3",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            },
                            {
                                "menuId": 384,
                                "name": "直播商品删除",
                                "url": "/playGoods/deleteGoods",
                                "path": null,
                                "type": 3,
                                "icon": null,
                                "parentId": 375,
                                "perms": "playGoods:deleteGoods",
                                "state": 1,
                                "sort": null,
                                "hideChildrenMenu": false,
                                "hideMenu": false,
                                "redirect": "",
                                "index": "9-5-3-4",
                                "children": null,
                                "roleId": 17,
                                "roleName": "开发组-技术部（刘伟武）"
                            }
                        ],
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 389,
                "name": "拼团团购",
                "url": "/marketing/groupbuying",
                "path": "groupbuying",
                "type": 2,
                "icon": "el-icon-shopping-cart-full",
                "parentId": 39,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "9-6",
                "children": [
                    {
                        "menuId": 390,
                        "name": "添加拼团",
                        "url": "/pt/insert",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 389,
                        "perms": "pt:insert",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-6-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 391,
                        "name": "编辑拼团",
                        "url": "/pt/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 389,
                        "perms": "pt:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-6-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 392,
                        "name": "拼团列表",
                        "url": "/pt/list",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 389,
                        "perms": "pt:list",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-6-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 393,
                        "name": "修改置顶状态",
                        "url": "/pt/update/top",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 389,
                        "perms": "pt:update:top",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-6-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 394,
                        "name": "删除单个或批量",
                        "url": "/pt/batch/delete",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 389,
                        "perms": "pt:batch:delete",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-6-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 395,
                        "name": "上下架单个或批量",
                        "url": "/pt/batch/update/state",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 389,
                        "perms": "pt:batch:update:state",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "9-6-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 422,
                "name": "推广员",
                "url": "/marketing/promoter",
                "path": "promoter",
                "type": 2,
                "icon": "el-icon-user",
                "parentId": 39,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "9-7",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 425,
                "name": "广告管理",
                "url": "/marketing/advertisement",
                "path": "advertisement",
                "type": 2,
                "icon": "el-icon-s-operation",
                "parentId": 39,
                "perms": null,
                "state": 0,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": true,
                "redirect": "",
                "index": "9-8",
                "children": [
                    {
                        "menuId": 426,
                        "name": "开屏广告",
                        "url": "/marketing/advertisement",
                        "path": "advertisement",
                        "type": 2,
                        "icon": null,
                        "parentId": 425,
                        "perms": null,
                        "state": 0,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": true,
                        "redirect": "",
                        "index": "9-8-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 427,
                        "name": "轮播图",
                        "url": "/shop/powerPoint",
                        "path": "powerPoint",
                        "type": 2,
                        "icon": "el-icon-video-camera",
                        "parentId": 425,
                        "perms": null,
                        "state": 0,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": true,
                        "redirect": "",
                        "index": "9-8-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 248,
        "name": "权限",
        "url": "/permission",
        "path": "permission",
        "type": 1,
        "icon": "el-icon-s-check",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 9,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "10",
        "children": [
            {
                "menuId": 249,
                "name": "角色管理",
                "url": "/permission/roleManagement",
                "path": "roleManagement",
                "type": 2,
                "icon": "el-icon-user",
                "parentId": 248,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "10-1",
                "children": [
                    {
                        "menuId": 252,
                        "name": "角色列表",
                        "url": "/admin/role/list",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 249,
                        "perms": "role:list",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 253,
                        "name": "添加角色",
                        "url": "/admin/role/add",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 249,
                        "perms": "role:add",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 254,
                        "name": "编辑角色",
                        "url": "/admin/role/edit",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 249,
                        "perms": "role:edit",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-1-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 255,
                        "name": "删除角色",
                        "url": "/admin/role/batchDelete",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 249,
                        "perms": "role:batchDelete",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-1-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 256,
                        "name": "修改启用状态",
                        "url": "/admin/role/updateState",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 249,
                        "perms": "role:updateState",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-1-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 257,
                        "name": "给角色绑定菜单",
                        "url": "/admin/role/bindMenu",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 249,
                        "perms": "role:bindMenu",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-1-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 250,
                "name": "管理员操作",
                "url": "/permission/administratorOperation",
                "path": "administratorOperation",
                "type": 2,
                "icon": "el-icon-monitor",
                "parentId": 248,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "10-2",
                "children": [
                    {
                        "menuId": 258,
                        "name": "管理员列表",
                        "url": "/admin/user/list",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 250,
                        "perms": "user:list",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 259,
                        "name": "管理员详情",
                        "url": "/admin/user/findById",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 250,
                        "perms": "user:findById",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 260,
                        "name": "添加管理员",
                        "url": "/admin/user/add",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 250,
                        "perms": "user:add",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 261,
                        "name": "编辑管理员",
                        "url": "/admin/user/edit",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 250,
                        "perms": "user:edit",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 262,
                        "name": "删除管理员",
                        "url": "/admin/user/batchDelete",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 250,
                        "perms": "user:batchDelete",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-2-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 263,
                        "name": "修改管理员状态",
                        "url": "/admin/user/updateState",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 250,
                        "perms": "user:updateState",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-2-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 251,
                "name": "操作日志",
                "url": "/permission/operationLog",
                "path": "operationLog",
                "type": 2,
                "icon": "el-icon-mouse",
                "parentId": 248,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "10-3",
                "children": [
                    {
                        "menuId": 264,
                        "name": "获取所有操作类型",
                        "url": "/admin/operate/groupAll",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 251,
                        "perms": "operate:groupAll",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-3-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 265,
                        "name": "日志列表",
                        "url": "/admin/operate/logList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 251,
                        "perms": "operate:logList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-3-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 275,
                "name": "菜单管理",
                "url": "/permission/menuManager",
                "path": "menuManager",
                "type": 2,
                "icon": "el-icon-notebook-1",
                "parentId": 248,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "10-4",
                "children": [
                    {
                        "menuId": 276,
                        "name": "获取所有菜单",
                        "url": "/admin/menu/findAll",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 275,
                        "perms": "menu:findAll",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-4-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 277,
                        "name": "添加菜单",
                        "url": "/admin/menu/add",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 275,
                        "perms": "menu:add",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-4-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 278,
                        "name": "编辑菜单",
                        "url": "/admin/menu/edit",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 275,
                        "perms": "menu:edit",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-4-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 279,
                        "name": "删除菜单",
                        "url": "/admin/menu/delete",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 275,
                        "perms": "menu:delete",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-4-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 280,
                        "name": "菜单明细",
                        "url": "/admin/menu/detail",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 275,
                        "perms": "menu:detail",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "10-4-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 41,
        "name": "设置",
        "url": "/setting",
        "path": "setting",
        "type": 1,
        "icon": "el-icon-s-tools",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 10,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "11",
        "children": [
            {
                "menuId": 59,
                "name": "版本设置",
                "url": "/setting/versionSet",
                "path": "versionSet",
                "type": 2,
                "icon": "el-icon-setting",
                "parentId": 41,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "11-1",
                "children": [
                    {
                        "menuId": 224,
                        "name": "版本设置详情",
                        "url": "/versions/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 59,
                        "perms": "versions:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-1-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 225,
                        "name": "保存版本设置",
                        "url": "/versions/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 59,
                        "perms": "versions:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-1-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 60,
                "name": "支付设置",
                "url": "/setting/paySet",
                "path": "paySet",
                "type": 2,
                "icon": "el-icon-sell",
                "parentId": 41,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "11-2",
                "children": [
                    {
                        "menuId": 226,
                        "name": "交易设置详情",
                        "url": "/tradeSettings/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 60,
                        "perms": "tradeSettings:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-2-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 227,
                        "name": "保存交易设置",
                        "url": "/tradeSettings/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 60,
                        "perms": "tradeSettings:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-2-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 228,
                        "name": "获取第三方支付配置信息",
                        "url": "/tradeSettings/getPayConfig",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 60,
                        "perms": "tradeSettings:getPayConfig",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-2-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 229,
                        "name": "保存第三方支付配置信息",
                        "url": "/tradeSettings/savePayConfig",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 60,
                        "perms": "tradeSettings:savePayConfig",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-2-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 61,
                "name": "消息推送",
                "url": "/setting/pushNotification",
                "path": "pushNotification",
                "type": 2,
                "icon": "el-icon-message",
                "parentId": 41,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "11-3",
                "children": [
                    {
                        "menuId": 230,
                        "name": "信息提醒列表",
                        "url": "/pushConfig/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 231,
                        "name": "编辑信息",
                        "url": "/pushConfig/sevPush",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:sevPush",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 232,
                        "name": "批量操作",
                        "url": "/pushConfig/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 233,
                        "name": "站内消息库明细",
                        "url": "/pushConfig/details",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:details",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 234,
                        "name": "模板搜索",
                        "url": "/pushConfig/getMessageList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:getMessageList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 235,
                        "name": "模板变量类型",
                        "url": "/pushConfig/modelType",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:modelType",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 236,
                        "name": "站内消息库列表",
                        "url": "/pushConfig/newsBank",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:newsBank",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 237,
                        "name": "添加模板",
                        "url": "/pushConfig/sevNewsBank",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:sevNewsBank",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-8",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 238,
                        "name": "编辑模板",
                        "url": "/pushConfig/updateNewsBank",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 61,
                        "perms": "pushConfig:updateNewsBank",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-3-9",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 62,
                "name": "短信设置",
                "url": "/setting/noteSet",
                "path": "noteSet",
                "type": 2,
                "icon": "el-icon-chat-round",
                "parentId": 41,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "11-4",
                "children": [
                    {
                        "menuId": 239,
                        "name": "短信配置详情接口",
                        "url": "/messagePortConfig/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 62,
                        "perms": "messagePortConfig:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-4-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 240,
                        "name": "保存短信配置",
                        "url": "/messagePortConfig/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 62,
                        "perms": "messagePortConfig:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-4-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 241,
                        "name": "添加模板",
                        "url": "/messagePortConfig/sevNoteModel",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 62,
                        "perms": "messagePortConfig:sevNoteModel",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-4-3",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 242,
                        "name": "编辑模板",
                        "url": "/messagePortConfig/updateNoteModel",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 62,
                        "perms": "messagePortConfig:updateNoteModel",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-4-4",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 243,
                        "name": "消息库列表",
                        "url": "/messagePortConfig/getList",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 62,
                        "perms": "messagePortConfig:getList",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-4-5",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 244,
                        "name": "消息库明细",
                        "url": "/messagePortConfig/details",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 62,
                        "perms": "messagePortConfig:details",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-4-6",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 245,
                        "name": "批量操作",
                        "url": "/messagePortConfig/del",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 62,
                        "perms": "messagePortConfig:del",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-4-7",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 63,
                "name": "其它设置",
                "url": "/setting/otherDynamics",
                "path": "otherDynamics",
                "type": 2,
                "icon": "el-icon-phone-outline",
                "parentId": 41,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": true,
                "hideMenu": false,
                "redirect": "",
                "index": "11-5",
                "children": [
                    {
                        "menuId": 246,
                        "name": "其它查询详情",
                        "url": "/otherSet/select",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 63,
                        "perms": "otherSet:select",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-5-1",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    },
                    {
                        "menuId": 247,
                        "name": "编辑其它查询",
                        "url": "/otherSet/update",
                        "path": null,
                        "type": 3,
                        "icon": null,
                        "parentId": 63,
                        "perms": "otherSet:update",
                        "state": 1,
                        "sort": null,
                        "hideChildrenMenu": false,
                        "hideMenu": false,
                        "redirect": "",
                        "index": "11-5-2",
                        "children": null,
                        "roleId": 17,
                        "roleName": "开发组-技术部（刘伟武）"
                    }
                ],
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 311,
        "name": "工单",
        "url": "/workOrders/work",
        "path": "workOrders",
        "type": 1,
        "icon": "el-icon-s-cooperation",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 11,
        "hideChildrenMenu": true,
        "hideMenu": false,
        "redirect": "",
        "index": "12",
        "children": [
            {
                "menuId": 312,
                "name": "获取工单列表",
                "url": "/lookProduct/getLookProductList",
                "path": null,
                "type": 3,
                "icon": null,
                "parentId": 311,
                "perms": "lookProduct:getLookProductList",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "12-1",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            }
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 405,
        "name": "审核",
        "url": "/audit/auditGoods",
        "path": "audit",
        "type": 1,
        "icon": "el-icon-s-opportunity",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 12,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "13",
        "children": [
            {
                "menuId": 406,
                "name": "商品操作",
                "url": "/audit/auditGoods",
                "path": "auditGoods",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 405,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "13-1",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 407,
                "name": "会员操作",
                "url": "/audit/auditMember",
                "path": "auditMember",
                "type": 2,
                "icon": "el-icon-user",
                "parentId": 405,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "13-2",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 408,
                "name": "运营操作",
                "url": "/audit/auditOperating",
                "path": "auditOperating",
                "type": 2,
                "icon": "el-icon-ship",
                "parentId": 405,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "13-3",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 410,
                "name": "操作列表",
                "url": "/admin/operate/audit/list",
                "path": null,
                "type": 3,
                "icon": null,
                "parentId": 405,
                "perms": "admin:operate:audit:list",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": true,
                "redirect": "",
                "index": "13-4",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 411,
                "name": "操作类型下拉框列表",
                "url": "/admin/operate/audit/category/list",
                "path": null,
                "type": 3,
                "icon": null,
                "parentId": 405,
                "perms": "admin:operate:audit:category:list",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": true,
                "redirect": "",
                "index": "13-5",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 412,
                "name": "审批",
                "url": "/admin/operate/audit/handle",
                "path": null,
                "type": 3,
                "icon": null,
                "parentId": 405,
                "perms": "admin:operate:audit:handle",
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": true,
                "redirect": "",
                "index": "13-6",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },  
             {
                "menuId": 413,
                "name": "客服审核",
                "url": "/audit/auditService",
                "path": "auditService",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 405,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "13-7",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 505,
        "name": "店铺管理",
        "url": "/store/storeList",
        "path": "store",
        "type": 1,
        "icon": "el-icon-shopping-bag-1",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 12,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "14",
        "children": [
            {
                "menuId": 506,
                "name": "店铺列表",
                "url": "/store/storeList",
                "path": "storeList",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 506,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "14-1",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
              {
                "menuId": 507,
                "name": "店铺等级",
                "url": "/store/storeLevel",
                "path": "storeLevel",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 506,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "14-2",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）",
            }
           
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）"
    },
    {
        "menuId": 605,
        "name": "平台审核",
        "url": "/examine/residencyApplication",
        "path": "examine",
        "type": 1,
        "icon": "el-icon-s-opportunity",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 12,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "15",
        "children": [
        {
                "menuId": 614,
                "name": "入驻申请审核",
                "url": "/examine/residencyApplication",
                "path": "residencyApplication",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 605,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "15-1",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 615,
                "name": "店铺资料审核",
                "url": "/examine/storeInformation",
                "path": "storeInformation",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 405,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "15-2",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
           
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）",
    },
    {
        "menuId": 705,
        "name": "平台店铺管理",
        "url": "/platstore/platstorelsit",
        "path": "examine",
        "type": 1,
        "icon": "el-icon-s-opportunity",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 12,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "16",
        "children": [
        {
                "menuId": 714,
                "name": "店铺列表",
                "url": "/platstore/platstorelsit",
                "path": "platstorelsit",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 705,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "16-1",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
            {
                "menuId": 715,
                "name": "店铺等级",
                "url": "/platstore/grade",
                "path": "grade",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 705,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "16-2",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
           
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）",
    },
    {
        "menuId": 755,
        "name": "商户店铺管理",
        "url": "/merchantstore/storemanagement",
        "path": "merchantstore",
        "type": 1,
        "icon": "el-icon-s-opportunity",
        "parentId": null,
        "perms": null,
        "state": 1,
        "sort": 12,
        "hideChildrenMenu": false,
        "hideMenu": false,
        "redirect": "",
        "index": "17",
        "children": [
        {
                "menuId": 760,
                "name": "店铺配置",
                "url": "/merchantstore/storemanagement",
                "path": "storemanagement",
                "type": 2,
                "icon": "el-icon-shopping-bag-1",
                "parentId": 755,
                "perms": null,
                "state": 1,
                "sort": null,
                "hideChildrenMenu": false,
                "hideMenu": false,
                "redirect": "",
                "index": "16-1",
                "children": null,
                "roleId": 17,
                "roleName": "开发组-技术部（刘伟武）"
            },
           
           
        ],
        "roleId": 17,
        "roleName": "开发组-技术部（刘伟武）",
    }
   
      ]
      }
    },
    computed: {
      menuTree() {
        const copyRouter = this.$store.getters.menuList;
        // const copyRouter = this.menuList
        return getMenuTree(copyRouter, 0);
      },
    },
  };
</script>
