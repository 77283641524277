import { Message } from 'element-ui';
// import parseTime, formatTime and set to filter

/**
 * @param {Object} obj 判空处理
 */
export const isEmpty = (obj) => {
  if (typeof obj === 'undefined' || obj === null || obj === '' || obj === []) {
    return true;
  } else {
    return false;
  }
};

/**
 * @param {Object} obj 导出Excel表 后缀：suffix
 * 导出功能：vue下载excel权限限制处理 exportExcel
 * 下载excel但是需要传token来验证的问题，一般情况下是用直接指向地址来实现，但是token校验没办法实现，所以就用到了blod
 */
export const exportExcel = (data, fileName, suffix = '.xlsx') => {
  // console.log(dateFormater('YYYYMMDDHHmmss'), '当前时间', nowTime())
  const time = dateFormater('YYYYMMDDHHmmss');
  const myFile = new File([data], fileName);
  // 创建一个a标签
  const aTag = document.createElement('a');
  // 文件名 + 时间 + 后缀 .xlsx
  aTag.download = fileName + time + suffix;
  // 获取url
  const href = URL.createObjectURL(myFile);
  aTag.href = href;
  aTag.click();
  // 释放url
  URL.revokeObjectURL(href);
};

/**
 * @param {Number} time 倒计时逻辑封装
 */
export const countDown = (time = 60) => {
  // 执行倒计时方法，一秒钟执行一次
  // 此方法后面跟的是毫秒级单位
  var set = setInterval(function () {
    --time;
  }, 1000);

  // time=60s 之后执行的方法
  setTimeout(function () {
    // 同时停止上面重复方法的执行
    clearInterval(set);
  }, time * 1000);
};

/**
 * @param {Array} arr 数组去重
 */
export const filterSetList = (arr, id = 'id') => {
  // console.log('数组去重', arr, id)
  // const id = 'id' // 这里定义按照过滤的对象的属性名称,你想要过滤的那个对象属性
  // const id = 'name' //试一下name
  const newArr = arr.reduce(
    (all, next) =>
      all.some((item) => item[id] === next[id]) ? all : [...all, next],
    []
  );
  // 把过滤好的数组重新赋值
  // arr = newArr
  // console.log(newArr, 11111111112)
  return newArr;
};

/**
 * @param {Array} arr 合并第一列相同的数据
 */
export const flitterData = (arr) => {
  const spanOneArr = [];
  let concatOne = 0;
  arr.forEach((item, index) => {
    if (index === 0) {
      spanOneArr.push(1);
    } else {
      // name 修改
      if (item.productId === arr[index - 1].productId) {
        // 第一列需合并相同内容的判断条件
        spanOneArr[concatOne] += 1;
        spanOneArr.push(0);
      } else {
        spanOneArr.push(1);
        concatOne = index;
      }
    }
  });
  return {
    one: spanOneArr,
  };
};
/**
 * @param {number} time 当前时间
 */
export const nowTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const date = now.getDate() >= 10 ? now.getDate() : '0' + now.getDate();
  const hour = now.getHours() >= 10 ? now.getHours() : '0' + now.getHours();
  const miu =
    now.getMinutes() >= 10 ? now.getMinutes() : '0' + now.getMinutes();
  const sec =
    now.getSeconds() >= 10 ? now.getSeconds() : '0' + now.getSeconds();
  return (
    +year +
    '年' +
    (month + 1) +
    '月' +
    date +
    '日 ' +
    hour +
    ':' +
    miu +
    ':' +
    sec
  );
};

/**
 * @param {number} time 格式化时间
 * dateFormater('YYYY-MM-DD HH:mm:ss')
 * dateFormater('YYYYMMDDHHmmss')
 */
export const dateFormater = (formater, time) => {
  const date = time ? new Date(time) : new Date();
  const Y = date.getFullYear() + '';
  const M = date.getMonth() + 1;
  const D = date.getDate();
  const H = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();
  return formater
    .replace(/YYYY|yyyy/g, Y)
    .replace(/YY|yy/g, Y.substr(2, 2))
    .replace(/MM/g, (M < 10 ? '0' : '') + M)
    .replace(/DD/g, (D < 10 ? '0' : '') + D)
    .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
    .replace(/mm/g, (m < 10 ? '0' : '') + m)
    .replace(/ss/g, (s < 10 ? '0' : '') + s);
};

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      );
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  if ((time + '').length === 10) {
    time = +time * 1000;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    date = new Date(parseInt(time));
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return timeStr;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    );
  }
}
export function getNowFormatDate() {
  var date = new Date();
  var seperator1 = '-';
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = '0' + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}
/* 数字 格式化 */
export function nFormatter(num, digits) {
  const si = [
    {
      value: 1e18,
      symbol: 'E',
    },
    {
      value: 1e15,
      symbol: 'P',
    },
    {
      value: 1e12,
      symbol: 'T',
    },
    {
      value: 1e9,
      symbol: 'G',
    },
    {
      value: 1e6,
      symbol: 'M',
    },
    {
      value: 1e3,
      symbol: 'k',
    },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
      );
    }
  }
  return num.toString();
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function toThousandslsFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}
// 验证手机号
export function checkPhone(rule, value, callback) {
  if (!value) {
    return callback(new Error('手机号不能为空'));
  } else {
    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  }
}
export function checkPassword(rule, value, callback) {
  if (!value) {
    return callback(new Error('密码不能为空'));
  } else if (value.length < 6) {
    callback(new Error('请至少输入 6 个字符。请不要使用容易被猜到的密码'));
  } else {
    callback();
  }
}
// 手机号证验证
export function checkTel(value, callback) {
  var reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  return reg.test(value);
}
// 身份证验证
export function checkiDNumber(value, callback) {
  var reg = /\d{17}[\d|x]|\d{15}/;
  return reg.test(value);
}
// 身份证验证
export function checkEmails(value, callback) {
  var reg = /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/;
  return reg.test(value);
}
// 邮箱验证
export function checkEmail(rule, value, callback) {
  if (!value) {
    return callback(new Error('邮箱不能为空'));
  } else {
    var reg = /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/;
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的邮箱'));
    }
  }
}
// 英文验证
export function checkCode(value, callback) {
  var reg = /^[A-Za-z]+$/g;
  return reg.test(value);
}
// qq验证
export function checkQq(value, callback) {
  var reg = /^[0-9]+$/g;
  return reg.test(value);
}
// 银行卡号
export function formatBankNo(BankNo, callback) {
  var strBin =
    '10,18,30,35,37,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,58,60,62,65,68,69,84,87,88,94,95,98,99';
  return strBin;
}
export function getStrleng(str, max) {
  var myLen = 0;
  for (var i = 0; i < str.length && myLen <= max * 2; i++) {
    if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
      myLen++;
    } else myLen += 2;
  }
  return myLen;
}
// 上传图片格式控制
export function updatedImg(file, obj, callback, func) {
  if (file.size < 10100000) {
    var fileName = file.name;
    var suffix = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toUpperCase();
    if (
      suffix === 'PDF' ||
      suffix === 'JPG' ||
      suffix === 'JPEG' ||
      suffix === 'PNG' ||
      suffix === 'GIF'
    ) {
      return true;
    } else {
      var tipType = '文件类型不正确,请重新上传';
      callback(tipType);
      return false;
    }
  } else {
    var tipSize = '文件大小超过5M,请重新上传';
    callback(tipSize);
    return false;
  }
}
// 上传文档格式控制
export function updatedFile(file, obj, callback, func) {
  if (file.size < 10100000) {
    var fileName = file.name;
    var suffix = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toUpperCase();
    if (
      suffix === 'DOC' ||
      suffix === 'DOCX' ||
      suffix === 'XLS' ||
      suffix === 'XLSX' ||
      suffix === 'PDF' ||
      suffix === 'ZIP' ||
      suffix === 'RAR'
    ) {
      return true;
    } else {
      var tipType = '文件类型不正确,请重新上传';
      callback(tipType);
      return false;
    }
  } else {
    var tipSize = '文件大小超过5M,请重新上传';
    callback(tipSize);
    return false;
  }
}
export function importFile(file, obj, callback, func) {
  if (file.size < 10100000) {
    var fileName = file.name;
    var suffix = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toUpperCase();
    if (suffix === 'XLS' || suffix === 'XLSX') {
      return true;
    } else {
      var tipType = '文件类型不正确,请重新上传';
      callback(tipType);
      return false;
    }
  } else {
    var tipSize = '文件大小超过10M,请重新上传';
    callback(tipSize);
    return false;
  }
}
export function minHeight(resfile) {
  return document.body.clientHeight - 180 + 'px';
}

export function formatDate(date, fmt = 'yyyy-MM-dd') {
  if (!(date instanceof Array)) {
    date = new Date(date);
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + '';
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}
export function getBlob(response) {
  const blob = new Blob([response.data], {
    type: 'application/vnd.ms-excel',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var filename = decodeURI(response.headers.filename);
  // link.download = filename + '.xls'
  link.download = filename;
  link.click();
}
// 图片 blob 流转化为可用 src
export function imgHandle(obj) {
  return window.URL.createObjectURL(obj);
}

// 复制文本功能
export function copy(text) {
  const oInput = document.createElement('input');
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select(); // 选择对象;
  document.execCommand('Copy'); // 执行浏览器复制命令
  document.body.removeChild(oInput);
}

// 复制到剪切板
export async function copyHandler(text) {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      const clipboardObj = navigator.clipboard;
      await clipboardObj.writeText(text);
    } else {
      let textArea = document.createElement('textarea');
      textArea.value = text;
      // 使text area不在viewport，同时设置不可见
      textArea.style.position = 'absolute';
      textArea.style.opacity = 0;
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      await new Promise((res, rej) => {
        // 执行复制命令并移除文本框
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
    Message.success('复制成功！');
  } catch (err) {
    Message.error('复制失败', err);
  }
}
