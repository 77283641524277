<template>
  <el-container style="height: 100%">
    <el-header style="padding: 0">
      <Navbar />
      <!-- <TagsView /> -->
    </el-header>
    <el-container style="height: calc(100% - 60px)">
      <el-aside style="width: 220px">
        <Menu />
      </el-aside>
      <el-main style="background-color: rgb(242, 243, 245)">
        <Breadcrumb style="margin-left: 15px" />
        <PageLayout />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  // import TagsView from '@/components/TagsView';
  import Navbar from '@/components/navbar/index.vue';
  import Menu from '@/components/menu/index.vue';
  import PageLayout from './page-layout.vue';
  import Breadcrumb from '@/components/Breadcrumb/index.vue';
  export default {
    // eslint-disable-next-line vue/no-reserved-component-names
    components: { Menu, Navbar, PageLayout, Breadcrumb },
  };
</script>
