// 导出属于工单的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'workOrders',
  path: '/workOrders',
  component: Layout,
  children: [
    {
      name: 'work',
      path: 'work',
      component: () => import('@/views/workOrders'),
      meta: {
        icon: 'el-icon-s-cooperation',
        title: '工单',
      },
    },
  ],
};
