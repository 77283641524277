// 导出属于商品的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'commodity',
  path: '/commodity',
  component: Layout,
  redirect: '/commodity/allGoods',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-goods',
    title: '商品',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'allGoods',
      path: 'allGoods',
      component: () => import('@/views/commodity/allGoods'),
      meta: {
        icon: 'el-icon-shopping-bag-2',
        title: '所有商品',
      },
    },
    // {
    //   name: 'waitAudit',
    //   path: 'waitAudit',
    //   component: () => import('@/views/commodity/waitAudit'),
    //   meta: {
    //     icon: 'el-icon-magic-stick',
    //     title: '待审核'
    //   }
    // },
    {
      name: 'warenspeicher',
      path: 'warenspeicher',
      component: () => import('@/views/commodity/warenspeicher'),
      meta: {
        icon: 'el-icon-box',
        title: '商品仓库',
      },
    },
    {
      name: 'brandManager',
      path: 'brandManager',
      component: () => import('@/views/commodity/brandManager'),

      meta: {
        icon: 'el-icon-postcard',
        title: '品牌管理',
      },
    },
    {
      name: 'factory',
      path: 'factory',
      component: () => import('@/views/commodity/factory'),
      meta: {
        icon: 'el-icon-office-building',
        title: '厂家管理',
      },
    },
    {
      name: 'special',
      path: 'special',
      component: () => import('@/views/commodity/special'),
      meta: {
        icon: 'el-icon-data-analysis',
        title: '专题活动',
      },
    },
    {
      name: 'remark',
      path: 'remark',
      component: () => import('@/views/commodity/remark'),
      meta: {
        icon: 'el-icon-chat-line-round',
        title: '商品评论',
      },
    },
    {
      name: 'classify',
      path: 'classify',
      component: () => import('@/views/commodity/classify'),
      meta: {
        icon: 'el-icon-connection',
        title: '商品分类',
      },
    },
    {
      name: 'attribute',
      path: 'attribute',
      component: () => import('@/views/commodity/attribute'),
      meta: {
        icon: 'el-icon-finished',
        title: '商品属性',
      },
    },
    {
      name: 'tourist',
      path: 'tourist',
      component: () => import('@/views/commodity/tourist'),
      meta: {
        icon: 'el-icon-shopping-bag-2',
        title: '游客商品',
      },
    },
  ],
};
