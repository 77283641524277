export function travel(_routes, layer) {
  if (!_routes) return null;

  const collector = _routes.map((element) => {
    // leaf node
    if (element.hideChildrenMenu || !element.children) {
      element.children = [];
      return element;
    }

    // route filter hideInMenu true
    element.children = element.children.filter((x) => x.hideMenu !== true);

    // Associated child node
    const subItem = travel(element.children, layer + 1);

    if (subItem.length) {
      element.children = subItem;
      return element;
    }

    // the else logic
    if (layer > 1) {
      element.children = subItem;
      return element;
    }

    if (element.hideMenu === false) {
      return element;
    }

    return null;
  });

  return collector.filter(Boolean);
}
