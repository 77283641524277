// 导出属于店铺的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'store',
  path: '/store',
  component: Layout,
  redirect: '/store/storeList',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-shop',
    title: '店铺管理',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'storeList',
      path: 'storeList',
      component: () => import('@/views/store/storeList'),
      meta: {
        icon: 'el-icon-files',
        title: '店铺列表',
      },
    },
    {
      name: 'storeLevel',
      path: 'storeLevel',
      component: () => import('@/views/store/storeLevel'),
      meta: {
        icon: 'el-icon-files',
        title: '店铺等级',
      },
    },
  ],
};
