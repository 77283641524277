// 导出属平台店铺管理的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'platstore',
  path: '/platstore',
  component: Layout,
  redirect: '/platstore/platstorelsit',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-shop',
    title: '平台店铺管理',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'platstorelsit',
      path: 'platstorelsit',
      component: () => import('@/views/platStore/platStoreLsit'),
      meta: {
        icon: 'el-icon-s-shop',
        title: '店铺列表',
      },
    },
    {
      name: 'grade',
      path: 'grade',
      component: () => import('@/views/platStore/grade'),
      meta: {
        icon: 'el-icon-s-data',
        title: '店铺等级',
      },
    },
  ],
};
