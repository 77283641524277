import Cookies from 'js-cookie';

const TokenKey = 'hyy_token';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

const TimeKey = 'logintime';

export function getTime() {
  return Cookies.get(TimeKey);
}

export function setTime(time) {
  return Cookies.set(TimeKey, time);
}

export function removeTime() {
  return Cookies.remove(TimeKey);
}
