// 定义递归组件
const MenuItem = {
  functional: true,
  props: {
    item: Object,
  },
  render(h, context) {
    const { item } = context.props;
    const children = item.children || [];
    return children.length > 0 ? (
      <el-submenu index={item.url}>
        <template slot="title">
          <i class={item.icon}></i>
          <span>{item.name}</span>
        </template>
        {children.map((child) => (
          <menu-item item={child} />
        ))}
      </el-submenu>
    ) : (
      <el-menu-item index={item.url}>
        <i class={item.icon}></i>
        <span>{item.name}</span>
      </el-menu-item>
    );
  },
};

export { MenuItem };
