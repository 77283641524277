<template>
  <div id="app">
    <router-view />

    <Notification />

    <!-- 绑定app盒子 -->
    <el-backtop
      target="#app"
      :visibility-height="100"
      :right="50"
      :bottom="100"
    />
  </div>
</template>

<script>
  import Notification from '@/components/Notification';

  export default {
    name: 'App',
    components: { Notification },

    mounted() {
      // document.documentElement 是全局变量时
      // const el = document.documentElement;
      // el.style.setProperty('--color-primary', '#7F43FE')
      // el.style.setProperty('--color-primary-light-9', '#ECF5FF');
      // el.style.setProperty('--color-success', '#13ce66');
      // el.style.setProperty('--color-success-light', '#e7faf0');
      // el.style.setProperty('--color-warning', '#ffba00');
      // el.style.setProperty('--color-warning-light', '#fff8e6');
      // el.style.setProperty('--color-danger', '#ff4d4f');
      // el.style.setProperty('--color-danger-light', '#ffeded');
    },
  };
</script>

<style>
  #app {
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
      'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    /* 这里给app一个滚动效果 */
    overflow-y: scroll;
  }
/* 修改滚动条轨道（背景）的样式 */
  ::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }
/* 修改滚动条滑块（拖动条）的样式 */
  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: rgb(201, 205, 212);
  }
</style>
