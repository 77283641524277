import request from '@/utils/request';

const state = {
  warnList: [],
};

const mutations = {
  changeWarnList(state, list) {
    state.warnList = list;
  },
};

const actions = {
  getProductWarnList({ state, commit, rootState }, params) {
    return new Promise((resolve, reject) => {
      const authList =
        rootState.user.userInfo.sysMenuList &&
        rootState.user.userInfo.sysMenuList[0]
          ? rootState.user.userInfo.sysMenuList[0].children
          : [];

      const bool = authList?.some((item) => item.url === 'product/warn/list');

      if (bool) {
        request({
          url: '/product/warn/list',
          params,
        }).then((res) => {
          commit('changeWarnList', res.list || []);

          resolve(true);
        });
      } else {
        resolve(false);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
