// 导出属平台运营中心的路由规则
import Layout from '@/layout/default-layout.vue';
// 每个子模块 其实 都是外层是layout  组件位于layout的二级路由里面
export default {
  name: 'platOperationsCenter',
  path: '/platOperationsCenter',
  component: Layout,
  redirect: '/platOperationsCenter/operationalToolsList',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-marketing',
    title: '平台运营中心',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'operationalToolsList',
      path: 'operationalToolsList',
      component: () => import('@/views/PlatformOperationsCenter/operationalToolsList'),
      meta: {
        icon: 'el-icon-scissors',
        title: '运营工具列表',
      },
    },
    {
      name: 'ppurchaseList',
      path: 'ppurchaseList',
      component: () => import('@/views/PlatformOperationsCenter/purchaseList'),
      meta: {
        icon: 'el-icon-sell',
        title: '购买列表',
      },
    },
  ],
};
