// 导出属于订单的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'order',
  path: '/order',
  component: Layout,
  redirect: '/order/orderList',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-order',
    title: '订单',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'orderList',
      path: 'orderList',
      component: () => import('@/views/order/orderList'),
      meta: {
        icon: 'el-icon-sold-out',
        title: '订单列表',
      },
    },
    {
      name: 'saleOrder',
      path: 'saleOrder',
      component: () => import('@/views/order/saleOrder'),
      meta: {
        icon: 'el-icon-sell',
        title: '售后订单',
      },
    },
    {
      name: 'gorderList',
      path: 'gorderList',
      component: () => import('@/views/order/gys/orderList'),
      meta: {
        icon: 'el-icon-sold-out',
        title: '供应商订单列表',
      },
    },
    {
      name: 'gsaleOrder',
      path: 'gsaleOrder',
      component: () => import('@/views/order/gys/saleOrder'),
      meta: {
        icon: 'el-icon-sell',
        title: '供应商售后订单',
      },
    },
  ],
};
