// 导出属于商店的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'shop',
  path: '/shop',
  component: Layout,
  redirect: '/shop/powerPoint',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-shop',
    title: '内容配置',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'nxdyigate',
      path: 'nxdyigate',
      component: () => import('@/views/shop/nxdyigate'),
      meta: {
        icon: 'el-icon-position',
        title: '导航图标',
      },
    },
    {
      name: 'advertisement',
      path: 'advertisement',
      component: () => import('@/views/shop/advertisement'),
      meta: {
        icon: 'el-icon-s-ticket',
        title: '启动广告',
      },
    },
    {
      name: 'powerPoint',
      path: 'powerPoint',
      component: () => import('@/views/shop/powerPoint'),
      meta: {
        icon: 'el-icon-video-camera',
        title: '轮播图',
      },
    },
    {
      name: 'advertising',
      path: 'advertising',
      component: () => import('@/views/shop/advertising'),
      meta: {
        icon: 'el-icon-video-camera',
        title: '其他广告',
      },
    },
    // {
    //   name: 'advertising',
    //   path: 'advertising',
    //   component: () => import('@/views/shop/advertisement'),
    //   meta: {
    //     icon: 'el-icon-s-operation',
    //     title: '广告配置',
    //   },
    //   children: [
    //     {
    //       name: 'advertisement',
    //       path: 'advertisement',
    //       component: () => import('@/views/shop/advertisement'),
    //       meta: {
    //         icon: 'el-icon-s-ticket',
    //         title: '启动广告',
    //       },
    //     },
    //     {
    //       name: 'powerPoint',
    //       path: 'powerPoint',
    //       component: () => import('@/views/shop/powerPoint'),
    //       meta: {
    //         icon: 'el-icon-video-camera',
    //         title: '幻灯片',
    //       },
    //     },
    //     {
    //       name: 'advertising',
    //       path: 'advertising',
    //       component: () => import('@/views/shop/advertising'),
    //       meta: {
    //         icon: 'el-icon-video-camera',
    //         title: '其他广告',
    //       },
    //     },
    //   ],
    // },
    {
      name: 'articleManager',
      path: 'articleManager',
      component: () => import('@/views/shop/articleManager'),
      meta: {
        icon: 'el-icon-tickets',
        title: '文章管理',
      },
    },
    {
      name: 'bulletinManagement',
      path: 'bulletinManagement',
      component: () => import('@/views/shop/bulletinManagement'),
      meta: {
        icon: 'el-icon-bell',
        title: '公告管理',
      },
    },
    {
      name: 'homePagePush',
      path: 'homePagePush',
      component: () => import('@/views/shop/homePagePush'),
      meta: {
        icon: 'el-icon-ship',
        title: '首页推送',
      },
    },
    {
      name: 'returnAddress',
      path: 'returnAddress',
      component: () => import('@/views/shop/returnAddress'),
      meta: {
        icon: 'el-icon-map-location',
        title: '退货地址',
      },
    },
    {
      name: 'materialManagement',
      path: 'materialManagement',
      component: () => import('@/views/shop/materialManagement'),
      meta: {
        icon: 'el-icon-folder-opened',
        title: '素材管理',
      },
    },
    {
      name: 'delivery',
      path: 'delivery',
      component: () => import('@/views/shop/delivery'),
      meta: {
        icon: 'el-icon-truck',
        title: '配送设置',
      },
    },
  ],
};
