import axios from 'axios';
import store from '@/store';
import resetMessage from '@/utils/resetMessage';
import router from '@/router';

// 请求基地址，做了一个 export 导出，引入 request.js 的时候可以通过解构获取到请求基地址
// 💥 用户登录测试账号，需要切换到测试服务器
// 正式环境接口地址
export const baseURL = process.env.VUE_APP_BASE_URL;
// 预发布环境接口地址
// export const baseURL = "https://apppre.api.hyylm.com/mallManage";
// 开发、本地环境接口地址
// export const baseURL = "http://192.168.188.159:9101/mallManage";
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (store.getters.token) {
      // 之前发现有 token 就注入
      config.headers.token = `${store.getters.token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 响应拦截器
// 这个拦截器函数可以接受两个参数, 都是回调函数
service.interceptors.response.use(
  (res) => {
    // console.log(res);
    // 1.网络成功的情况
    // console.log(res, '网络成功的情况')
    const { code, msg, data } = res.data;
    if (code === 200) {
      // 数据成功
      // return res.data.data
      return data;
    } else {
      // 导出 Excel表
      if (res.request.responseType === 'blob') {
        // console.log(res.request.responseURL, '导出Excel表路径')
        // return res.request.responseURL
        return res;
      }
      if (code === 401) {
        store.dispatch('user/logout');
        router.push('/login');
      }
      // 数据失败
      // Message.error(msg)
      resetMessage.error(msg || '操作失败');
      // return Promise.reject(new Error(msg));
      return res.data;
    }
  },
  (err) => {
    // 请求已经发出, 并且响应得到网络层面的失败, 这种失败可能性可蛮多
    // 2.网错错误的情况
    // Message.error(err.msg)
    resetMessage.error(err.msg);
    return Promise.reject(err);
  }
);

export default service;
