// 引入动态路由
// import { asyncRoutes, constantRoutes } from '@/router';

const state = {
  // routes: [] || JSON.parse(localStorage.getItem('routes')),
};
const mutations = {
  // setRoutes(state, data) {
  //   // 存菜单: 静态路由 + 动态路由
  //   state.routes = [...constantRoutes, ...data];
  //   // console.log(state.routes, '存菜单')
  //   localStorage.setItem('routes', JSON.stringify(state.routes));
  // },
};
const actions = {
  // 筛选出 动态路由中和menus中能够对上的路由
  // fiterRoutes(store, menus) {
  //   // asyncRoutes 本地所有的菜单路由
  //   // menus 请求的角色的菜单路由
  //   // _arrRoutes () => router[]
  //   const _arrRoutes = (menus, localRoutes) => {
  //     const list = [];
  //     localRoutes.filter((item) =>
  //       menus.some((ele) => {
  //         const routeChild = _arrRoutes(
  //           ele.children ?? [],
  //           item.children ?? []
  //         );
  //         if (routeChild.length) item.children = routeChild;
  //         if (item.path === ele.url) {
  //           list.push(item);
  //         }
  //       })
  //     );
  //     return list;
  //   };
  //   const routes = _arrRoutes(menus, asyncRoutes);
  //   // 筛选出结果后, 要恢复菜单
  //   store.commit('setRoutes', routes);
  //   return routes;
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
