import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';

import router from '@/router/index';
import store from '@/store/index';

router.beforeEach((to, from, next) => {
  NProgress.start();
  const token = store.getters.token;

  if (token) {
    if (to.path === '/login') next(store.getters.defaultRoute?.url);
    else next();
  } else {
    if (to.path === '/login') {
      next();
      return;
    }
    next('/login');
  }
});

router.afterEach(() => {
  NProgress.done();
});
