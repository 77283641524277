import Vue from 'vue'

import adaptive from './adaptive'

Vue.directive('adaptive', adaptive)


/* 
* vue2自定义指令 
 *   功能：限制el-input数字输入框输入位数 
 *   使用方法：v-number-input.fu="6", ().fu写了表示可以输入负数
 *   没写默认不可以输入负数，"6"表示最多能输入6位数字，不写默认最多输入9位，为亿 
*/
Vue.directive('number-input',
    {
        // 如果输入框输入内容不显示就是输入框 没有用v-model绑定数据
        bind: (el, binding, vnode) => {
            try {
                // 获取el-input中的input输入框  因为指令绑定元素最外层是div
                const ell = el.querySelector('input');
                // console.log(ell);
                // console.log('绑定成功');
                let timer = null;
                // 传入参数值为数字 表示位数，只能输入多少位的数字 ，不算小数位数
                let length = binding.value || 9;
                // 计算多少位数字的最大值 如5位数最大为99999 ，比100000小的数就是
                let maxnum = Math.pow(10, length);
                //修饰符 是否支持输入负数，默认不能输入负数  如果.fu则可以输入负数
                let fu = binding.modifiers.fu;
                ell.addEventListener('input', (e) => {
                    // 去掉边框样式
                    ell.style.borderColor = '';
                    // console.log(e.data); //e.data == ell.value
                    const value = parseFloat(ell.value);
                    if (value < 0) {
                        // 表示可以输入负数
                        if (fu) return;
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            ell.value = '';
                            ell.setAttribute('placeholder', '请输入大于0的数');
                            ell.style.borderColor = 'red';
                            // this.$message.error('不能输入负数');
                            // vue组件示例  相当于this  element-ui的message弹出提示
                            vnode.context.$message({
                                type: 'error',
                                message: '不能输入负数喔~!',
                            });
                        }, 1500);
                    }
                    // 判端最大值
                    if (value >= maxnum) {
                        console.log(value, '指令输入值，需要v-model绑定');
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                            ell.value = '';
                            ell.setAttribute('placeholder', `请输入小于${length + 1}位数的数字`);
                            ell.style.borderColor = 'red';
                            // vue组件示例  相当于this
                            vnode.context.$message({
                                type: 'error',
                                message: `不能输入大于${length}位数的数字喔~!`,
                            });
                        }, 1500);
                    }
                });
            } catch (error) {
                console.error(error);
            }
        },
    })

