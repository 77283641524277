// 导出属于会员的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'member',
  path: '/member',
  component: Layout,
  redirect: '/member/memberManagement',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-custom',
    title: '会员',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'memberManagement',
      path: 'memberManagement',
      component: () => import('@/views/member/memberManagement'),
      meta: {
        icon: 'el-icon-user',
        title: '会员管理',
      },
    },
    {
      name: 'memberOrder',
      path: 'memberOrder',
      component: () => import('@/views/member/memberOrder'),
      meta: {
        icon: 'el-icon-wallet',
        title: '会员等级',
      },
    },
    // {
    //   name: 'operateService',
    //   path: 'operateService',
    //   component: () => import('@/views/member/operateService'),
    //   meta: {
    //     icon: 'el-icon-monitor',
    //     title: '运营客服'
    //   }
    // },
    {
      name: 'memberCard',
      path: 'memberCard',
      component: () => import('@/views/member/memberCard'),
      meta: {
        icon: 'el-icon-bank-card',
        title: '会员卡',
      },
    },
  ],
};
