const state = {
  // 素材管理 选中的 图片，视频 的数据集
  // 选中的 Id 集合
  selectIdArrId: [],
  // 选中的数据
  selectData: [],
  // 商品列表 - 分页数据
  page: {
    page: 1, // 当前页码\
    size: 10,
    activeName: 'first',
    // 筛选条件-添加时间
    addDate: null,
    // 筛选条件-商品名称/编号
    name: null,
    categoryId: null,
    warehouseId: null,
  },
};

const mutations = {
  // 选中的 Id 集合
  setSelectIdArrId(state, data) {
    state.selectIdArrId = data;
  },
  // 清空 - 选中的 Id 集合
  removeSelectIdArrId(state) {
    state.selectIdArrId = [];
  },
  // 选中的数据
  setSelectData(state, data) {
    state.selectData = data;
  },
  // 清空 - 选中的数据
  removeSelectData(state) {
    state.selectData = [];
  },
  // 商品列表 - 分页
  setPage(state, data) {
    state.page = { ...state.page, ...data };
    // console.log('商品列表 - 分页', state.page)
  },
  // 清空 - 商品列表 - 分页
  removePage(state) {
    state.page = { page: 1, size: 10 };
  },
};

const actions = {
  // 修改数据 - 选中的 Id 集合
  async setSelectIdArrId(store, data) {
    // console.log('setSelectIdArrId 成功逻辑')
    // console.log(data)
    store.commit('setSelectIdArrId', data);
  },
  // 清空数据 - 选中的 Id 集合
  removeSelectIdArrId(store) {
    store.commit('removeSelectIdArrId');
  },
  // 修改数据 - 选中的数据
  async setSelectData(store, data) {
    // console.log('setSelectData 成功逻辑')
    // console.log(data)
    store.commit('setSelectData', data);
  },
  // 清空数据 - 选中的数据
  removeSelectData(store) {
    store.commit('removeSelectData');
  },
  // 修改数据 - 商品列表 - 分页
  async setPage(store, data) {
    // console.log('setPage 成功逻辑')
    // console.log(data)
    store.commit('setPage', data);
  },
  // 清空数据 - 商品列表 - 分页
  removePage(store) {
    store.commit('removePage');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
