/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  // const valid_map = ['admin', 'editor']
  // return valid_map.indexOf(str.trim()) >= 0
  return /^[\u4e00-\u9fa5A-Za-z0-9]*$/.test(str);
}
export function validUserPassword(str) {
  return /^[0-9A-Za-z~!@#$%^&*]{6,18}$/.test(str);
}

import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import zh from 'vee-validate/dist/locale/zh_CN'; // 引入中文文件
Validator.addLocale(zh);
const config = {
  locale: 'zh_CN',
  fieldsBagName: 'fieldBags',
};
Vue.use(VeeValidate, config);

// 自定义validate
const dictionary = {
  zh_CN: {
    messages: {
      required: (field) => '请输入' + field,
      number: (field) => field + '必须为数值',
      url: () => '请输入正确格式的服务器地址',
      ip: () => '请输入正确的IP地址',
    },
    attributes: {
      ip: 'IP地址',
      httpPort: 'HTTP端口',
      userName: '账号',
      mobile: '手机号码',
      emailAdd: '邮件地址',
      authCode: '您收到的验证码',
      loginPwd: '登录密码(6~18位)',
      traderpwd: '交易密码(6~18位)',
      recommender: '您的推荐人',
      isSee: '阅读用户协议，并同意',
      npwdLog: '新的登录密码',
      qrwdLog: '确认登录密码',
      number: '整数',
      numberA: '整数',
      numberB: '整数',
      name: '内容',
      title: '内容',
      text: '内容',
      textA: '内容',
      wxName: '内容',
      anchorName: '主播昵称',
      money: '金额',
      moneyA: '金额',
      moneyB: '金额',
      moneyC: '金额',
      moneyD: '内容',
      discount: '折扣',
      urlA: 'url',
      numberCases: '数量',
    },
  },
};
Validator.updateDictionary(dictionary);

// 1.IPV4地址
Validator.extend('ipAddr', {
  messages: {
    zh_CN: (field) => '请输入正确的IPV4地址',
  },
  validate: (value) => {
    return /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/.test(
      value
    );
  },
});

// 2.端口【0~65535】
Validator.extend('port', {
  messages: {
    zh_CN: (field) => '请输入正确格式的端口号',
  },
  validate: (value) => {
    return /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{4}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/.test(
      value
    );
  },
});

// 3.账号
Validator.extend('userName', {
  messages: {
    zh_CN: (field) => '账号不允许特殊符号和中文',
  },
  validate: (value) => {
    return /^[A-Za-z0-9]*$/.test(value);
  },
});

// 4.手机号
Validator.extend('mobile', {
  messages: {
    zh_CN: (field) => '手机号格式不正确',
  },
  validate: (value) => {
    return /^1[2-9]\d{9}$/.test(value);
  },
});

// 5.邮箱
Validator.extend('email', {
  messages: {
    zh_CN: (field) => '邮箱格式不正确',
  },
  validate: (value) => {
    return /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(
      value
    );
  },
});

// 6.验证码
Validator.extend('code', {
  messages: {
    zh_CN: (field) => '验证码格式不正确',
  },
  validate: (value) => {
    return /^[0-9]*$/.test(value);
  },
});

// 6.登录密码
Validator.extend('loginPwd', {
  messages: {
    zh_CN: (field) => '登录密码格式错误',
  },
  validate: (value) => {
    // return /^\S{6,18}$/.test(value)
    return /^[0-9A-Za-z~!@#$%^&*]{6,18}$/.test(value);
  },
});

// 7.交易密码
Validator.extend('tradpwd', {
  messages: {
    zh_CN: (field) => '交易密码格式不正确',
  },
  validate: (value) => {
    return /^\S{6,18}$/.test(value);
  },
});

// 8.推荐人
Validator.extend('recom', {
  messages: {
    zh_CN: (field) => '推荐人格式不正确',
  },
  validate: (value) => {
    return /^[^\u4e00-\u9fa5]{0,}$/.test(value);
  },
});

// 9.用户协议
Validator.extend('see', {
  messages: {
    zh_CN: (field) => '请阅读用户协议，并同意',
  },
  validate: (value) => {
    return true;
  },
});

// 10.忘记密码，新的登录密码
Validator.extend('npwd', {
  messages: {
    zh_CN: (field) => '登录密码格式不正确[6~18位]',
  },
  validate: (value) => {
    return /^\S{6,18}$/.test(value);
  },
});

// 11.忘记密码，确认新的登录密码
Validator.extend('qrwd', {
  messages: {
    zh_CN: (field) => '登录密码格式不正确[6~18位]',
  },
  validate: (value) => {
    return /^\S{6,18}$/.test(value);
  },
});

// 排序 numeric - 只允许数字
Validator.extend('numeric', {
  messages: {
    zh_CN: (field) => '用户昵称不允许特殊符号',
  },
  validate: (value) => {
    return /^[\u4e00-\u9fa5A-Za-z0-9]*$/.test(value);
  },
});

// 名称 - 品牌名称、仓库名称
Validator.extend('name', {
  messages: {
    zh_CN: (field) => '不允许特殊符号',
  },
  validate: (value) => {
    // eslint-disable-next-line no-useless-escape
    return /^[\u4e00-\u9fa5A-Za-z0-9\w\+\-=_@#$%^&;:,.<>/~\\\[\]()（）{}?!|]*$/.test(
      value
    );
  },
});

// 名称 - 商品标题
Validator.extend('title', {
  messages: {
    zh_CN: (field) => '不能为空',
  },
  validate: (value) => {
    return true;
    // return /^[\u4e00-\u9fa5A-Za-z0-9\w\+\-=_@#$%^&;:,.<>/~\\\[\](){}?!|]*$/.test(value)
    // return /^[\u4e00-\u9fa5A-Za-z0-9\w\+\-=_@#$%^&;:,.<>/~\\\[\]\、\（\）\：\！\ (){}?!|]*$/.test(value)
  },
});

// 文本 - 不能为空
Validator.extend('text', {
  messages: {
    zh_CN: (field) => '不能为空',
  },
  validate: (value) => {
    // return /^[\u4e00-\u9fa5A-Za-z0-9\w\+\-=_@#$%^&;:,.<>/~\\\[\]\、\（\）\：\！\ (){}?!|]*$/.test(value)
    return true;
  },
});

// 文本 - 不能为空
Validator.extend('textA', {
  messages: {
    zh_CN: (field) => '不能为空',
  },
  validate: (value) => {
    // return /^[\u4e00-\u9fa5A-Za-z0-9\w\+\-=_@#$%^&;:,.<>/~\\\[\]\、\（\）\：\！\ (){}?!|]*$/.test(value)
    return true;
  },
});

Validator.extend('wxName', {
  messages: {
    zh_CN: (field) => '最短3个汉字，最长17个汉字',
  },
  validate: (value) => {
    // eslint-disable-next-line no-useless-escape
    return /^([\u4e00-\u9fa5A-Za-z0-9\w\+\-=_@#$%^&;:,.<>/~\\\[\]\、\!\ (){}?!|]{3,17})$/.test(
      value
    );
  },
});

Validator.extend('anchorName', {
  messages: {
    zh_CN: (field) => '最短2个汉字，最长15个汉字',
  },
  validate: (value) => {
    // eslint-disable-next-line no-useless-escape
    return /^([\u4e00-\u9fa5A-Za-z0-9\w\+\-=_@#$%^&;:,.<>/~\\\[\]\、\!\ (){}?!|]{2,15})$/.test(
      value
    );
  },
});

// 地址 - 品牌官方地址 url:{domain?} - (指定域名的)url
Validator.extend('url', {
  messages: {
    zh_CN: (field) => 'url格式不正确',
  },
  validate: (value) => {
    return /^[a-zA-z]+:\/\/[^\s]*$/.test(value);
  },
});

Validator.extend('urlA', {
  messages: {
    zh_CN: (field) => 'url格式不正确',
  },
  validate: (value) => {
    // eslint-disable-next-line no-useless-escape
    return /^[a-zA-z\-]+:\/\/[^\s]*$/.test(value);
  },
});

// 营业执照
Validator.extend('charter', {
  messages: {
    zh_CN: (field) => '营业执照格式不正确',
  },
  validate: (value) => {
    return /^[^\u4e00-\u9fa5]/.test(value);
  },
});

// 金额  非零开头的最多带两位小数的数字
Validator.extend('money', {
  messages: {
    zh_CN: (field) => '保留 2 位小数',
  },
  validate: (value) => {
    return /^([0-9][0-9]*)+(\.[0-9]{1,2})?$/.test(value);
    // return /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/.test(value)
  },
});

// 出售价、出售价、会员价
Validator.extend('moneyA', {
  messages: {
    zh_CN: (field) => '保留 2 位小数',
  },
  validate: (value) => {
    return /^\d+\.?\d{0,2}$/.test(value);
  },
});

Validator.extend('moneyB', {
  messages: {
    zh_CN: (field) => '保留 2 位小数',
  },
  validate: (value) => {
    return /^\d+\.?\d{0,2}$/.test(value);
    // return /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/.test(value)
  },
});

Validator.extend('moneyC', {
  messages: {
    zh_CN: (field) => '保留 2 位小数',
  },
  validate: (value) => {
    return /^\d+\.?\d{0,2}$/.test(value);
  },
});

Validator.extend('moneyD', {
  messages: {
    zh_CN: (field) => '保留 2 位小数',
  },
  validate: (value) => {
    return /^\d+\.?\d{0,2}$/.test(value);
  },
});

Validator.extend('discount', {
  messages: {
    zh_CN: (field) => '保留 1 位小数',
  },
  validate: (value) => {
    return /^([1-9][0-9]*)+(\.[0-9]{1})?$/.test(value);
    // return /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/.test(value)
  },
});

// 银行卡号
Validator.extend('cardNo', {
  messages: {
    zh_CN: (field) => '银行卡号格式不正确',
  },
  validate: (value) => {
    return /^\d{16}|\d{19}$/.test(value);
  },
});

// 身份证 - 18位身份证号码的基本格式校验
Validator.extend('idCard', {
  messages: {
    zh_CN: (field) => '身份证格式不正确',
  },
  validate: (value) => {
    return /^[1-9]\d{5}[1-9]\d{3}((0[1-9])|(1[0-2]))((0[1-9])|([1-2][0-9])|(3[0-1]))\d{3}(\d|x|X)$/.test(
      value
    );
  },
});

// 数字
Validator.extend('number', {
  messages: {
    zh_CN: (field) => '必须为整数',
  },
  validate: (value) => {
    return /^\d+$/.test(value);
  },
});

Validator.extend('numberA', {
  messages: {
    zh_CN: (field) => '必须为整数',
  },
  validate: (value) => {
    return /^\d+$/.test(value);
  },
});

Validator.extend('numberB', {
  messages: {
    zh_CN: (field) => '必须为整数',
  },
  validate: (value) => {
    return /^\d+$/.test(value);
  },
});

// 件数 -  数字
Validator.extend('numberCases', {
  messages: {
    zh_CN: (field) => '必须为整数',
  },
  validate: (value) => {
    return /^\d+$/.test(value);
  },
});

// 同一父分类下子分类不能重名的特殊校验
Validator.extend('className', {
  messages: {
    zh_CN: (field) => '同一分类下不能重名',
  },
  validate: (value) => {
    return /^\d+$/.test(value);
  },
});
// 1. 判断有没有重名
// 1.1 获取全部的部门列表
// 1.2 筛选出同一父部门的子部门
// 1.3 在筛选结果中, 找找看有没有 name === val
// 算法二数组高阶函数
// const isRepeat = this.depts
// .filter(item => item.pid === this.nodeData.id)
// .some(item => item.name === val)
