// 导出属于数据统计的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'statistics',
  path: '/statistics',
  component: Layout,
  // redirect: '/statistics/salesStatistics',
  redirect: '/statistics/honyone',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-data',
    title: '数据统计',
  },
  // 配置二级路的路由表
  children: [
    // {
    //   name: 'salesPage',
    //   path: 'salesPage',
    //   component: () => import('@/views/statistics/salesPage'),
    //   meta: {
    //     icon: 'el-icon-price-tag',
    //     title: '销售首页'
    //   }
    // },
    // {
    //   name: 'dataOverview',
    //   path: 'dataOverview',
    //   component: () => import('@/views/statistics/dataOverview'),
    //   meta: {
    //     icon: 'el-icon-bangzhu',
    //     title: '数据概况'
    //   }
    // },
    // {
    //   name: 'salesStatistics',
    //   path: 'salesStatistics',
    //   component: () => import('@/views/statistics/salesStatistics'),
    //   meta: {
    //     icon: 'el-icon-data-line',
    //     title: '销售统计'
    //   }
    // },
    // {
    //   name: 'balance',
    //   path: 'balance',
    //   component: () => import('@/views/statistics/balance'),
    //   meta: {
    //     icon: 'el-icon-money',
    //     title: '余额明细'
    //   }
    // },
    // {
    //   name: 'integration',
    //   path: 'integration',
    //   component: () => import('@/views/statistics/integration'),
    //   meta: {
    //     icon: 'el-icon-help',
    //     title: '积分明细'
    //   }
    // },
    {
      name: 'honyone',
      path: 'honyone',
      component: () => import('@/views/statistics/honyone'),
      meta: {
        icon: 'el-icon-bank-card',
        title: '厂家品牌',
      },
    },
  ],
};
