// 导出属于营销的路由规则
import Layout from '@/layout/default-layout.vue';
export default {
  name: 'marketing',
  path: '/marketing',
  component: Layout,
  redirect: '/marketing/coupon',
  alwaysShow: true,
  meta: {
    icon: 'el-icon-s-marketing',
    title: '营销',
  },
  // 配置二级路的路由表
  children: [
    {
      name: 'coupon',
      path: 'coupon',
      component: () => import('@/views/marketing/coupon'),
      meta: {
        icon: 'el-icon-s-ticket',
        title: '优惠券',
      },
    },
    {
      name: 'seckilling',
      path: 'seckilling',
      component: () => import('@/views/marketing/seckilling'),
      meta: {
        icon: 'el-icon-time',
        title: '整点秒杀',
      },
    },
    {
      name: 'agency',
      path: 'agency',
      component: () => import('@/views/marketing/agency'),
      meta: {
        icon: 'el-icon-camera',
        title: '代理中心',
      },
    },
    {
      name: 'promoter',
      path: 'promoter',
      component: () => import('@/views/marketing/promoter'),
      meta: {
        icon: 'el-icon-user',
        title: '推广员',
      },
    },
    {
      name: 'integralMall',
      path: 'integralMall',
      component: () => import('@/views/marketing/integralMall/index.vue'),
      redirect: '/marketing/integralMall/integralSet',
      meta: {
        icon: 'el-icon-present',
        title: '积分商场',
      },
      children: [
        {
          name: 'integralSet',
          path: 'integralSet',
          component: () => import('@/views/marketing/integralMall/integralSet'),
          meta: {
            icon: 'el-icon-setting',
            title: '基础设置',
          },
        },
        {
          name: 'sortManagement',
          path: 'sortManagement',
          component: () =>
            import('@/views/marketing/integralMall/sortManagement'),
          meta: {
            icon: 'el-icon-s-operation',
            title: '分类管理',
          },
        },
        {
          name: 'merchandiseControl',
          path: 'merchandiseControl',
          component: () =>
            import('@/views/marketing/integralMall/merchandiseControl'),
          meta: {
            icon: 'el-icon-goods',
            title: '商品管理',
          },
        },
        {
          name: 'exchangeRecord',
          path: 'exchangeRecord',
          component: () =>
            import('@/views/marketing/integralMall/exchangeRecord'),
          meta: {
            icon: 'el-icon-document',
            title: '兑换记录',
          },
        },
      ],
    },
    {
      name: 'appletLive',
      path: 'appletLive',
      component: () => import('@/views/marketing/appletLive/index.vue'),
      redirect: '/marketing/appletLive/sinatv',
      meta: {
        icon: 'el-icon-video-play',
        title: '小程序直播',
      },
      children: [
        {
          name: 'sinatv',
          path: 'sinatv',
          component: () => import('@/views/marketing/appletLive/sinatv'),
          meta: {
            icon: 'el-icon-video-camera',
            title: '直播列表',
          },
        },
        // {
        //   name: 'playCategory',
        //   path: 'playCategory',
        //   component: () => import('@/views/marketing/appletLive/playCategory'),
        //   meta: {
        //     icon: 'el-icon-s-operation',
        //     title: '直播分类'
        //   }
        // },
        {
          name: 'liveGoods',
          path: 'liveGoods',
          component: () => import('@/views/marketing/appletLive/liveGoods'),
          meta: {
            icon: 'el-icon-goods',
            title: '直播商品',
          },
        },
      ],
    },
    {
      name: 'groupbuying',
      path: 'groupbuying',
      component: () => import('@/views/marketing/groupbuying'),
      meta: {
        icon: 'el-icon-shopping-cart-full',
        title: '拼团团购',
      },
    },
  ],
};
